import { all, takeLatest, takeEvery, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import { canboxes, createCanbox } from '../../api'
import actions from './actions'

function* LOAD({ payload }) {
  yield put(request(canboxes(payload), 'canboxes'))
}

function* CREATE({ payload }) {
  yield put(request(createCanbox(payload), 'canboxes/create'))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeEvery(actions.CREATE, CREATE)])
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('DONE', 'maintenance/done'),
}

export default actions

export function done(id) {
  return {
    type: actions.DONE,
    payload: {
      id,
    },
  }
}

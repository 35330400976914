import { net, getToken } from 'ca-admin-core'
import CONSTANTS from './constants'

const { API } = CONSTANTS
const { get } = net

const alertsVehicles = params => get(`${API.ALERTS}/vehicles`, params, getToken())
const alertsBikes = params => get(`${API.ALERTS}/bikes`, params, getToken())
const alertsMaintenance = params => get(`${API.ALERTS}/maintenance`, params, getToken())

export default {
  alertsVehicles,
  alertsMaintenance,
  alertsBikes,
}

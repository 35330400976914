import { all, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import { canboxDetails, updateCanbox } from '../../api'
import actions from './actions'

function* LOAD({ payload: { id } }) {
  yield put(request(canboxDetails(id), 'canboxDetails'))
}

function* UPDATE({ payload: { id, params } }) {
  yield put(request(updateCanbox(id, params), actions.UPDATE))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Data successfully saved.',
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_SUCCESS, SUCCESS),
  ])
}

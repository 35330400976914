import React from 'react'
import { Badge } from 'antd'

export default class BatteryBadge extends React.Component {
  render() {
    const { data } = this.props

    if (!data || !data.batteryVoltage) {
      return 'n / a'
    }

    const { batteryVoltage: level, batteryPercentage: percentage = null } = data

    if (!percentage) {
      return `${level} V`
    }

    let status = 'success'

    if (percentage >= 30 && percentage < 50) {
      status = 'warning'
    } else if (percentage < 30) {
      status = 'error'
    }

    return <Badge status={status} text={`${level} V (${percentage}%)`} />
  }
}

import { net, getToken } from 'ca-admin-core'
import { API_URL } from 'utils/constants'

const { get, postJson, destroy } = net

export const url = `${API_URL}/plans`

const List = params => get(url, params, getToken())
const Create = params => postJson(url, params, getToken())
const Details = params => get(`${url}/${params.id}`, null, getToken())
const Update = params => postJson(`${url}/${params.id}`, params, getToken())
const Destroy = params => destroy(`${url}/${params.id}`, null, getToken())

export default {
  List,
  Create,
  Details,
  Update,
  Destroy,
}

import actions from './actions'

const initialState = {}

export default function maintenanceDoneReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case actions.DONE_REQUEST:
      return {
        ...state,
        [payload.extra]: {
          loading: true,
        },
      }
    case actions.DONE_SUCCESS:
    case actions.DONE_ERROR:
      return {
        ...state,
        [payload.data]: {
          loading: false,
        },
      }
    default:
      return state
  }
}

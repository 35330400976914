import { all, takeEvery, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userListCC } = API

function* LIST_CC({ payload: { id } }) {
  yield put(request(userListCC(id), actions.LIST_CC))
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST_CC, LIST_CC)])
}

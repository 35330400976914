import { net, getToken } from 'ca-admin-core'
import CONSTANTS from './constants'

const { API } = CONSTANTS
const { get } = net

const feedback = params => get(API.FEEDBACK, params, getToken())
const feedbackDetails = id => get(`${API.FEEDBACK}/${id}`, null, getToken())

export default {
  feedback,
  feedbackDetails,
}

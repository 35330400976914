export function listItems(metadata, filter, stringKeys = false) {
  const items = Object.entries(metadata || {}).map(([key, value]) => ({
    text: value,
    value: stringKeys ? key : parseInt(key, 10),
  }))

  if (filter) {
    return items.filter(m => filter.includes(m.value))
  }

  return items
}

export function getItem(metadata, key) {
  return metadata && metadata[key]
}

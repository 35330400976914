import { core } from 'ca-admin-core'
import { ADMIN_ROLES } from 'utils/constants'
import dashboard from './dashboard'
import alerts from './alerts'
import users from './users'
import trips from './trips'
import feedback from './feedback'
import maintenance from './maintenance'
import vehicles from './vehicles'
import damages from './damages'
import promocodes from './promocodes'
import equipment from './equipment'
import reports from './reports'
import security from './security'
import fleets from './fleets'
import logs from './logs'
import layers from './layers'
import competition from './competition'
import pricings from './pricings'
import vehicleTypes from './vehicleTypes'
import mobileApp from './mobileApp'
import plans from './plans'

export default [
  core({
    adminRoles: ADMIN_ROLES,
  }),
  dashboard,
  reports,
  alerts,
  users,
  trips,
  feedback,
  maintenance,
  vehicles,
  damages,
  promocodes,
  equipment,
  fleets,
  logs,
  layers,
  competition,
  security,
  pricings,
  vehicleTypes,
  mobileApp,
  plans,
]

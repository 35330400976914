import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('vehicleTypes/destroy'),
}

const destroy = payload => {
  return {
    type: actions.LOAD,
    payload,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, destroy, reset }

import React from 'react'
import { Icon, Button, Badge, Popover } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Marker, Popup } from 'react-leaflet'

import { VEHICLE_TYPE_CAR, VEHICLE_TYPE_BIKE } from 'utils/constants'

import { CarBatteryBadge, BikeBatteryBadge } from 'modules/vehicles'

import { load, reset } from '../../redux/map/actions'
import { getPin, getColor } from './utils'
import VehicleScore, { VehicleScoreOverall } from '../VehicleScore'

const allScoreOptions = ['voltage', 'fuel', 'sattellites', 'alerts', 'data_transmission']

@withRouter
@connect(({ maintenanceMap }) => ({ maintenanceMap }))
export default class MaintenanceMap extends React.Component {
  interval = null

  componentDidMount() {
    const { autoreload } = this.props

    this.load()

    if (autoreload) {
      this.enableAutoReload()
    }
  }

  componentDidUpdate(prevProps) {
    const { autoreload, type, dispatch } = this.props
    const { autoreload: prevAutoreload, type: prevType } = prevProps

    if (type !== prevType) {
      dispatch(reset())
      this.load()
    }

    if (autoreload !== prevAutoreload) {
      if (autoreload) {
        this.enableAutoReload()
      } else {
        this.disableAutoReload()
      }
    }
  }

  componentWillUnmount() {
    this.disableAutoReload()
  }

  load = () => {
    const { type, dispatch } = this.props
    const filters = {}

    if (type) {
      filters.type = [type]
    }

    dispatch(load({ filters }))
  }

  enableAutoReload = () => {
    this.interval = setInterval(() => this.load(), 30 * 1000)
  }

  disableAutoReload = () => {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  getScore = vehicle => {
    const { scoreOptions = allScoreOptions } = this.props

    let score = 100

    scoreOptions.forEach(option => {
      score = Math.min(score, vehicle.score[option])
    })

    return score
  }

  render() {
    const {
      maintenanceMap: { data },
      only,
    } = this.props

    let vehicles = data

    if (only) {
      // only is an array of vehicle uuids
      vehicles = vehicles.filter(vehicle => only.includes(vehicle.uuid))
    }

    return vehicles.map(vehicle => {
      const {
        location,
        alerts,
        uuid,
        model,
        last_data_transmission: lastTransmission,
        battery_level: voltage,
        battery_percentage: percent,
        fuel_level: fuel,
        identification_number: plate,
        type_id: vehicleType,
      } = vehicle

      const gmapUrl = `https://www.google.com/maps?q=${location.lat},${location.lng}`
      const wazeUrl = `https://waze.com/ul?ll=${location.lat},${location.lng}&navigate=yes`

      const scoreDetails = (
        <div style={{ minWidth: 150 }}>
          <VehicleScore vehicle={vehicle} />
        </div>
      )

      return (
        <Marker key={uuid} position={location} icon={getPin(vehicle, this.getScore(vehicle))}>
          <Popup>
            <div>
              <Link to={{ pathname: `/vehicles/${uuid}`, state: { modal: true } }}>
                <Button type="primary" block>
                  {plate}
                </Button>
              </Link>
            </div>
            <div className="mt-2">
              <strong>{model}</strong>
            </div>
            <div className="mt-2">
              {vehicleType === VEHICLE_TYPE_CAR && (
                <>
                  <CarBatteryBadge level={voltage} /> · {fuel} %
                </>
              )}
              {vehicleType === VEHICLE_TYPE_BIKE && (
                <BikeBatteryBadge data={{ batteryVoltage: voltage, batteryPercentage: percent }} />
              )}
              <div className="mt-2">
                <Icon type="warning" /> {alerts || 0} · <Icon type="cloud-sync" />{' '}
                {moment
                  .utc(lastTransmission)
                  .local()
                  .fromNow()}
              </div>
            </div>
            <div className="mt-2">
              <Popover content={scoreDetails} trigger="click" placement="top">
                <Badge
                  color={getColor(vehicle)}
                  text={<VehicleScoreOverall vehicle={vehicle} />}
                  style={{ cursor: 'pointer' }}
                />
              </Popover>
            </div>
            <div className="mt-2">
              <Button href={gmapUrl}>GMaps</Button>
              <Button href={wazeUrl} className="ml-1">
                Waze
              </Button>
            </div>
          </Popup>
        </Marker>
      )
    })
  }
}

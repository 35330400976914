import { getToken, net } from 'ca-admin-core'
import { postJson } from 'ca-admin-core/dist/utils/net'
import { FLEET_API_URL } from 'utils/constants'

const { get, putJson } = net

const API_VEHICLES = `${FLEET_API_URL}/vehicles`
const API_METADATA = `${FLEET_API_URL}/metadata`

// Metadata

export const metadata = () => get(`${API_METADATA}`, null, getToken())

// Vehicles

export const vehicles = params => get(API_VEHICLES, params, getToken())
export const vehicleDetails = uuid => get(`${API_VEHICLES}/${uuid}`, null, getToken())
export const vehicleUpdate = (uuid, params) =>
  putJson(`${API_VEHICLES}/${uuid}`, params, getToken())
export const vehicleHistory = (uuid, params) =>
  get(`${API_VEHICLES}/${uuid}/history`, params, getToken())
export const vehicleHistoryRoute = (uuid, params) =>
  get(`${API_VEHICLES}/${uuid}/history/route`, params, getToken())
export const vehicleMaintenance = (uuid, params) =>
  get(`${API_VEHICLES}/${uuid}/maintenance`, params, getToken())
export const assignToPOI = uuid => putJson(`${API_VEHICLES}/${uuid}/poi`, null, getToken())
export const assignModem = (uuid, params) =>
  putJson(`${API_VEHICLES}/${uuid}/modem`, params, getToken())
export const assignCanbox = (uuid, params) =>
  putJson(`${API_VEHICLES}/${uuid}/canbox`, params, getToken())
export const vehiclesMap = params => get(`${API_VEHICLES}/vehiclesLocations`, params, getToken())
export const vehicleDamage = uuid => get(`${API_VEHICLES}/${uuid}/damage`, null, getToken())
export const vehicleFindBy = params => postJson(`${API_VEHICLES}/findBy`, params, getToken())
export const vehicleClearAlerts = uuid =>
  get(`${API_VEHICLES}/${uuid}/clearAlerts`, null, getToken())

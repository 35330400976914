import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userUpdateDetails } = API

function* UPDATE_DETAILS({ payload: { id, params } }) {
  yield put(request(userUpdateDetails(id, params), actions.UPDATE_DETAILS))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'User profile updated.',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_DETAILS, UPDATE_DETAILS),
    takeLatest(actions.UPDATE_DETAILS_SUCCESS, SUCCESS),
  ])
}

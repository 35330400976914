import { net, getToken } from 'ca-admin-core'
import CONSTANTS from './constants'

const { API } = CONSTANTS
const { get, postJson, putJson, destroy } = net

export const users = params => get(API.USERS, params, getToken())
export const usersNegativeBalanceExport = params =>
  get(`${API.USERS}/negativeBalanceExport`, params, getToken())
const userDetails = id => get(`${API.USERS}/${id}`, null, getToken())
const userUpdateDetails = (id, params) => putJson(`${API.USERS}/${id}`, params, getToken())
const userUpdateRoles = (id, params) => putJson(`${API.USERS}/${id}/roles`, params, getToken())
const userUpdateStatus = (id, params) => putJson(`${API.USERS}/${id}/status`, params, getToken())
const userDestroy = id => destroy(`${API.USERS}/${id}`, null, getToken())
const userUpdatePassport = (id, params) =>
  putJson(`${API.USERS}/${id}/documents`, params, getToken())
const userUpdateLicense = (id, params) =>
  putJson(`${API.USERS}/${id}/documents`, params, getToken())
const userTopupWallet = (id, params) => putJson(`${API.USERS}/${id}/wallet`, params, getToken())
const userListCC = id => get(`${API.USERS}/${id}/cc`, null, getToken())
export const userPush = (id, params) => postJson(`${API.USERS}/${id}/push`, params, getToken())

const userResetPassword = email => postJson(API.RESETPASSWORD, email, getToken())
const userValidateDocument = (id, params) => putJson(`${API.DOCUMENTS}/${id}`, params, getToken())

export const scanQR = params => postJson(`${API.USERS}/scanqr`, params, getToken())
export const uploadImage = (id, params) => ({
  name: 'file',
  action: `${API.USERS}/${id}/image`,
  headers: {
    authorization: `Bearer ${getToken()}`,
  },
  data: params,
  accept: 'image/jpeg', // Only useful to pick items on File Explorer. DOES NOT exclude other types
  showUploadList: false,
  // Enforce image MIME-type ONLY
  beforeUpload: file => {
    if (file.type.startsWith('image')) {
      return true
    }
    return false
  },
  // Resize and change image quality regardless of the input file
  // Aspect ratio will me maintained and sized of file reduced
  transformFile: file => {
    return new Promise(resolve => {
      const maxWidth = 2000
      const maxHeight = 2000

      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        const img = new Image()
        img.src = reader.result

        img.onload = async () => {
          const elem = document.createElement('canvas')

          const ratio = Math.min(maxWidth / img.width, maxHeight / img.height)
          const width = img.width * ratio
          const height = img.height * ratio

          elem.width = width
          elem.height = height

          const ctx = elem.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          const blob = await new Promise(res => elem.toBlob(res, 'image/jpeg', 0.5))

          const newFile = await new File([blob], `resized_${file.name}`, {
            type: 'image/jpeg',
            lastModified: new Date(),
          })

          resolve(newFile)
        }
      }
    })
  },
})

export default {
  users,
  usersNegativeBalanceExport,
  userDetails,
  userUpdateDetails,
  userUpdateRoles,
  userUpdateStatus,
  userResetPassword,
  userDestroy,
  userUpdatePassport,
  userUpdateLicense,
  userValidateDocument,
  userTopupWallet,
  userPush,
  userListCC,
  scanQR,
  uploadImage,
}

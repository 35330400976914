import React from 'react'
import { Tag } from 'antd'

export default class MaintenancePendingTag extends React.Component {
  getTag = pending => {
    let color = ''
    let text = 'no'

    switch (pending) {
      case 1:
        color = 'orange'
        text = 'yes'
        break
      default:
        color = ''
        text = 'no'
    }

    return <Tag color={color}>{text}</Tag>
  }

  render() {
    const { pending } = this.props

    return this.getTag(pending)
  }
}

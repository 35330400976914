import { all, takeEvery, takeLatest, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import { maintenanceBulkCreate } from '../../api'
import actions from './actions'

function* BULK_CREATE({ payload }) {
  yield put(request(maintenanceBulkCreate(payload), actions.BULK_CREATE))
}

function* SUCCESS() {
  const count = yield select(state => state.maintenanceBulkCreate.count)

  yield notification.info({
    message: 'Done!',
    description: `${count} entries were created.`,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.BULK_CREATE, BULK_CREATE),
    takeLatest(actions.BULK_CREATE_SUCCESS, SUCCESS),
  ])
}

import { Loadable } from 'ca-admin-core'

export default {
  name: 'security',
  menu: [
    {
      title: 'Security',
      key: 'security',
      url: '/security',
      icon: 'icmn icmn-shield',
      roles: ['admin', 'security-operator'],
    },
  ],
  routes: [
    {
      path: '/security',
      component: Loadable(() => import('modules/security/pages')),
      exact: true,
    },
  ],
  reducers: {},
  sagas: [],
}

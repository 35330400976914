import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userUpdateStatus } = API

function* UPDATE_STATUS({ payload: { id, params } }) {
  yield put(request(userUpdateStatus(id, params), actions.UPDATE_STATUS))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Status successfully updated.',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_STATUS, UPDATE_STATUS),
    takeLatest(actions.UPDATE_STATUS_SUCCESS, SUCCESS),
  ])
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('ADD_TRIP', 'user/addTrip'),
}

export default actions

export function addTrip(params) {
  return {
    type: actions.ADD_TRIP,
    payload: {
      params,
    },
  }
}

import React from 'react'
import { Badge } from 'antd'

export default class LightsTag extends React.Component {
  render() {
    let { level } = this.props

    if (!level) {
      return 'n / a'
    }

    level = parseFloat(level)

    let status = 'success'

    if (level >= 11 && level < 11.5) {
      status = 'warning'
    } else if (level < 11) {
      status = 'error'
    }

    return <Badge status={status} text={`${level} V`} />
  }
}

import actions from './actions'

const initialState = {
  loading: false,
  count: 0,
}

export default function maintenanceBulkCreateReducer(state = initialState, action) {
  switch (action.type) {
    case actions.BULK_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.BULK_CREATE_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false,
        count: action.payload.data,
      }
    case actions.BULK_CREATE_ERROR:
      return {
        ...initialState,
        ...action.payload,
      }
    case actions.BULK_CREATE_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

import React from 'react'
import { Tag } from 'antd'

export default class GearboxTag extends React.Component {
  getTag = (gearbox, status) => {
    let color

    if (gearbox === 'P') {
      color = ''
    } else {
      color = status === 'free' ? 'red' : 'green'
    }

    return <Tag color={color}>{gearbox}</Tag>
  }

  render() {
    const { data, status } = this.props

    if (!data || !data.cangoStatus || data.cangoStatus.length === 0) {
      return <Tag>n / a</Tag>
    }

    const {
      cangoStatus: { gearbox },
    } = data

    return this.getTag(gearbox, status)
  }
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('BULK_CREATE', 'maintenance/bulk/create'),
}

export default actions

export function bulkCreate(payload) {
  return {
    type: actions.BULK_CREATE,
    payload,
  }
}

import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('appVehicleTypes'),
}

const list = payload => {
  return {
    type: actions.LOAD,
    payload,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, list, reset }

import { getBaseAuthHeaders, net } from 'ca-admin-core'

const API_URL = 'https://sniffer.api.citylink.ro/api'
const { get } = net

const headers = getBaseAuthHeaders('BqLpNN6zBq', 'jeeIC10PFK')

export const income = params => get(`${API_URL}/incomeUsage`, params, null, headers)
export const routes = params => get(`${API_URL}/routes`, params, null, headers)
export const heatmap = params => get(`${API_URL}/departuresHeatmap`, params, null, headers)

import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'

import { vehicleClearAlerts } from '../../api'
import actions from './actions'

function* CLEAR_ALERTS({ payload: { uuid } }) {
  yield put(request(vehicleClearAlerts(uuid), actions.CLEAR_ALERTS))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Alerts cleared.',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CLEAR_ALERTS, CLEAR_ALERTS),
    takeLatest(actions.CLEAR_ALERTS_SUCCESS, SUCCESS),
  ])
}

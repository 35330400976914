import React from 'react'
import { Table, Button, Tag } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import TripStatusTag from '../tags/Status'
import TripTypeTag from '../tags/Type'

class TripsTable extends React.Component {
  render() {
    const { trips, hideType, hideUser, hideVehicle } = this.props

    const columns = [
      {
        title: 'UUID',
        dataIndex: 'uuid',
        sorter: false,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: record => <TripTypeTag type={record} />,
        filters: [
          { text: 'car', value: '1' },
          { text: 'bike', value: '2' },
        ],
        hidden: hideType,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: record => <TripStatusTag status={record} />,
        filters: [
          { text: 'ended', value: 'ended' },
          { text: 'started', value: 'started' },
        ],
      },
      {
        title: 'State',
        dataIndex: 'state',
        render: record => <Tag>{record}</Tag>,
      },
      {
        title: 'Started at',
        dataIndex: 'started_at',
        sorter: true,
        render: date =>
          moment
            .utc(date)
            .local()
            .format('YYYY-MM-DD HH:mm'),
      },
      {
        title: 'Ended at',
        dataIndex: 'ended_at',
        sorter: true,
        render: date =>
          date &&
          moment
            .utc(date)
            .local()
            .format('YYYY-MM-DD HH:mm'),
      },
      {
        title: 'User',
        sorter: false,
        render: record =>
          record.user && (
            <Link
              to={{ pathname: `/users/${record.user.id}`, state: { modal: true } }}
              className="utils__link--underlined"
            >
              {record.user.first_name} {record.user.last_name}
            </Link>
          ),
        hidden: hideUser,
      },
      {
        title: 'Phone',
        dataIndex: 'user.phone_number',
        sorter: false,
        hidden: hideUser,
      },
      {
        title: 'Vehicle',
        sorter: false,
        render: record =>
          record.vehicle && (
            <Link
              to={{ pathname: `/vehicles/${record.vehicle.uuid}`, state: { modal: true } }}
              className="utils__link--underlined"
            >
              {record.vehicle.identification_number}
            </Link>
          ),
        hidden: hideVehicle,
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: true,
        render: price => `${price} LEI`,
      },
      {
        title: 'Billed',
        dataIndex: 'billed',
        sorter: true,
        render: billed => `${billed} LEI`,
      },
      {
        title: 'Actions',
        key: 'action',
        render: record => (
          <Link to={{ pathname: `/trips/${record.uuid}`, state: { modal: true } }}>
            <Button icon="eye" className="mr-1" size="small">
              Manage
            </Button>
          </Link>
        ),
      },
    ]

    return (
      <Table
        className="utils__scrollTable"
        dataSource={trips}
        columns={columns.filter(column => column.hidden !== true)}
        pagination={false}
        rowKey="id"
      />
    )
  }
}

export default TripsTable

import React from 'react'
import { Button, Tag } from 'antd'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { getItem, listItems } from 'utils/metadata'
import { DataTable } from 'ca-admin-core'

import WarningSign from 'components/WarningSign'
import { done } from '../../redux/done/actions'
import MaintenanceTypeTag from '../tags/Type'
import MaintenancePendingTag from '../tags/Pending'

@withRouter
@connect(({ metadata, user, maintenanceDone }) => ({ metadata, user, maintenanceDone }))
export default class MaintenanceList extends React.Component {
  handleDoneClick = id => {
    const { dispatch } = this.props

    dispatch(done(id))
  }

  getTypeTagBorder = type => {
    switch (type) {
      case 'refuel':
        return '#91d5ff'
      case 'wash':
        return '#d3adf7'
      case 'charge':
        return '#b7eb8f'
      case 'move':
        return '#ffa39e'
      case 'sanitize':
        return '#87e8de'
      case 'inventory':
        return '#adc6ff'
      default:
        return '#d9d9d9'
    }
  }

  render() {
    const { metadata, hiddenColumns = [], history, user, maintenanceDone, ...rest } = this.props

    const {
      maintenanceTag,
      maintenanceTypeDocuments,
      maintenanceTypeServices,
      maintenanceTypeWheels,
      maintenanceTypeMaintenance,
    } = metadata

    const allMaintenanceTypes = {
      ...maintenanceTypeDocuments,
      ...maintenanceTypeServices,
      ...maintenanceTypeWheels,
      ...maintenanceTypeMaintenance,
    }

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        render: id => (
          <Link
            to={{ pathname: `/maintenance/${id}`, state: { modal: true } }}
            className="utils__link--underlined"
          >
            #{id}
          </Link>
        ),
      },
      {
        title: 'Tag',
        dataIndex: 'tag',
        render: tag => <MaintenanceTypeTag tag={getItem(maintenanceTag, tag)} />,
        filters: listItems(maintenanceTag),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: type => {
          const maintenanceTypeText = getItem(allMaintenanceTypes, type)
          const borderColor = this.getTypeTagBorder(maintenanceTypeText)
          return <Tag style={{ borderColor }}>{maintenanceTypeText}</Tag>
        },
        filters: listItems(allMaintenanceTypes),
      },
      {
        title: 'Vehicle',
        dataIndex: 'vehicle_uuid',
        sorter: false,
        render: (uuid, record) => (
          <Link
            to={{ pathname: `/vehicles/${uuid}`, state: { modal: true } }}
            className="utils__link--underlined"
          >
            {record.vehicle_identification_number}
          </Link>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'operated_at',
      },
      {
        title: 'Expires',
        dataIndex: 'expire_date',
        render: (expireDate, record) => {
          const expireText = record.days_left < 0 ? 'Expired!' : `${record.days_left} days left!`
          return (
            (expireDate && (
              <span>
                {expireDate}
                {record.days_left && record.days_left < 30 && (
                  <WarningSign warning={expireText} className="ml-1" />
                )}
              </span>
            )) ||
            'n / a'
          )
        },
      },
      {
        title: 'Mileage',
        dataIndex: 'operated_km',
        render: mileage => mileage || 'n / a',
      },
      {
        title: 'Mileage limit',
        dataIndex: 'expire_km',
        render: (expireKm, record) => {
          const expireText = record.km_left < 0 ? 'Expired!' : `${record.km_left} km left!`
          const MAX_MILEAGE = 1000000
          return (
            <span>
              {expireKm !== MAX_MILEAGE && expireKm !== 0 ? expireKm : 'n / a'}
              {expireKm !== MAX_MILEAGE && expireKm !== 0 && record.km_left < 1000 && (
                <WarningSign warning={expireText} className="ml-4" />
              )}
            </span>
          )
        },
      },
      // {
      //   title: 'Operator',
      //   dataIndex: 'operated_by',
      //   render: operator => operator || 'n / a',
      // },
      {
        title: 'Assignee',
        dataIndex: 'assigned_to',
        sorter: false,
        render: (assignedTo, record) =>
          assignedTo ? (
            <Link
              to={{ pathname: `/users/${assignedTo}`, state: { modal: true } }}
              className="utils__link--underlined"
            >
              {record.assigned_to_name}
            </Link>
          ) : (
            'n / a'
          ),
      },
      {
        title: 'To do',
        dataIndex: 'pending',
        render: pending => <MaintenancePendingTag pending={pending} />,
        // filters: [
        //   { text: 'yes', value: 1 },
        //   { text: 'no', value: 0 }
        // ]
      },
      {
        title: 'Actions',
        key: 'action',
        render: record => {
          const { [record.id]: { loading } = {} } = maintenanceDone
          const pending = record.pending === 1

          return (
            <>
              {pending && (
                <Button
                  loading={loading}
                  onClick={() => this.handleDoneClick(record.id)}
                  type="primary"
                  icon="check-circle"
                  size="small"
                >
                  Done
                </Button>
              )}
              <Link to={{ pathname: `/maintenance/${record.id}`, state: { modal: true } }}>
                <Button icon="eye" className="ml-1" size="small">
                  Manage
                </Button>
              </Link>
            </>
          )
        },
      },
    ]

    return (
      <DataTable
        columns={columns.filter(column => !hiddenColumns.includes(column.dataIndex))}
        rowDoubleClick={record => {
          history.push({ pathname: `/maintenance/${record.id}`, state: { modal: true } })
        }}
        {...rest}
      />
    )
  }
}

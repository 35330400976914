import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from '../../api'
import { actions } from './actions'
import { actions as actionsShow } from '../show/actions'

const { Update } = API

function* UPDATE({ payload }) {
  yield put(request(Update(payload), actions.LOAD))
}

function* SUCCESS(payload) {
  yield notification.success({
    message: 'Success!',
    description: 'Plan updated',
  })

  yield put({
    type: actionsShow.SUCCESS,
    payload: payload.payload,
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, UPDATE), takeLatest(actions.SUCCESS, SUCCESS)])
}

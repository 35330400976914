import { all, takeEvery, takeLatest, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import { maintenanceBulkDelete } from '../../api'
import actions from './actions'

function* BULK_DELETE({ payload }) {
  yield put(request(maintenanceBulkDelete(payload), actions.BULK_DELETE))
}

function* SUCCESS() {
  const count = yield select(state => state.maintenanceBulkDelete.count)

  yield notification.info({
    message: 'Done!',
    description: `${count} entries were deleted.`,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.BULK_DELETE, BULK_DELETE),
    takeLatest(actions.BULK_DELETE_SUCCESS, SUCCESS),
  ])
}

import actions from './actions'

const initialState = {
  loading: false,
  data: {},
  modem: {},
  canbox: {},
  update: {},
  trips: [],
  documents: [],
  services: [],
}

export default function vehicleDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...initialState,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case actions.ERROR:
      return {
        ...initialState,
      }
    case actions.RESET:
      return {
        ...initialState,
      }
    case actions.UPDATE_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      }
    case actions.UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        update: {
          loading: false,
        },
      }
    case actions.UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...action.payload,
          loading: false,
        },
      }
    default:
      return state
  }
}

import vehicleTypes from './list/reducers'
import vehicleTypeDetails from './show/reducers'
import vehicleTypeUpdate from './update/reducers'
import vehicleTypeCreate from './create/reducers'
import vehicleTypeDestroy from './destroy/reducers'

export default {
  vehicleTypes,
  vehicleTypeDetails,
  vehicleTypeUpdate,
  vehicleTypeCreate,
  vehicleTypeDestroy,
}

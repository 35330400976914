import React from 'react'
import Pusher from 'pusher-js'
import { Button, Tag, notification } from 'antd'

import { info, warning } from './audio'

const infoAudio = new Audio(info)
const warningAudio = new Audio(warning)

// Helpers

const styleWarning = {
  backgroundColor: '#fffbe6',
  border: '1px solid #ffe58f',
}

const styleInfo = {
  backgroundColor: '#e6f7ff',
  border: '1px solid #91d5ff',
}

const linkToVehicle = vehicle => {
  const { origin } = window.location
  return `${origin}/vehicles/${vehicle.uuid}`
}

const linkToUser = user => {
  const { origin } = window.location
  return `${origin}/users/${user.id}`
}

const message = (title, tagText, tagColor) => (
  <>
    {title}
    {tagText && ' '}
    {tagText && (
      <Tag color={tagColor} className="ml-1">
        {tagText}
      </Tag>
    )}
  </>
)

const action = (text, url, type) => (
  <Button type={type} className="mt-3" block href={url}>
    {text}
  </Button>
)

const userName = user => `${user.first_name} ${user.last_name}`

// Initialize Pusher

const { REACT_APP_PUSHER_KEY } = process.env

const pusher = new Pusher(REACT_APP_PUSHER_KEY, {
  cluster: 'eu',
  encrypted: true,
})

// Exported methods

export const subscribe = () => {
  const channel = pusher.subscribe('alerts')

  channel.bind('bike', data => {
    const { vehicle = {}, event } = data

    notification.warning({
      duration: 0,
      message: message('Bike alert!', event, 'red'),
      description: action(vehicle.identification_number, linkToVehicle(vehicle), 'danger'),
      style: styleWarning,
    })

    warningAudio.play()
  })

  channel.bind('user_blocked', data => {
    const { user = {} } = data

    notification.info({
      duration: 0,
      message: message('User blocked!'),
      description: action(userName(user), linkToUser(user), 'primary'),
      style: styleInfo,
    })

    infoAudio.play()
  })

  channel.bind('car_battery', data => {
    const { vehicle = {} } = data

    notification.info({
      duration: 0,
      message: message('Car battery discharging!'),
      description: action(vehicle.identification_number, linkToVehicle(vehicle), 'primary'),
      style: styleInfo,
    })

    infoAudio.play()
  })

  channel.bind('car_city', data => {
    const { vehicle = {} } = data

    notification.info({
      duration: 0,
      message: message('Trip ended outside origin city!'),
      description: action(vehicle.identification_number, linkToVehicle(vehicle), 'primary'),
      style: styleInfo,
    })

    infoAudio.play()
  })
}

export const unsubscribe = () => {
  pusher.unsubscribe('alerts')
}

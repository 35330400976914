import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('plans/create'),
}

const create = payload => {
  return {
    type: actions.LOAD,
    payload,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, create, reset }

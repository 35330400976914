import { actions } from './actions'

const initialState = {
  loading: false,
  weekday: [],
  weekend: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case actions.ERROR:
    case actions.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

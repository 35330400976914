import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('LIST_CC', 'user/wallet/listcc'),
}

export default actions

export function listCC(id) {
  return {
    type: actions.LIST_CC,
    payload: {
      id,
    },
  }
}

import { net, getToken } from 'ca-admin-core'
import CONSTANTS from './constants'

const { API } = CONSTANTS
const { get, postJson } = net

const trips = params => get(API.TRIPS, params, getToken())
const tripDetails = uuid => get(`${API.TRIPS}/${uuid}`, null, getToken())
export const startTrip = params => postJson(API.TRIPS, params, getToken())
const endTrip = uuid => postJson(`${API.TRIPS}/${uuid}/close`, null, getToken())
const retryPayment = uuid => postJson(`${API.TRIPS}/${uuid}/retryPayment`, null, getToken())

export default {
  trips,
  tripDetails,
  startTrip,
  endTrip,
  retryPayment,
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('DESTROY', 'user/destroy'),
}

export default actions

export function destroy(id) {
  return {
    type: actions.DESTROY,
    payload: {
      id,
    },
  }
}

import { Loadable } from 'ca-admin-core'

import fleets from './redux/list/reducers'
import fleetsSaga from './redux/list/sagas'

import fleetDetails from './redux/details/reducers'
import fleetDetailsSaga from './redux/details/sagas'

import fleetUpdate from './redux/update/reducers'
import fleetUpdateSaga from './redux/update/sagas'

import fleetCreate from './redux/create/reducers'
import fleetCreateSaga from './redux/create/sagas'

export { url } from './api'

export default {
  name: 'fleets',
  menu: [
    {
      title: 'Fleets',
      key: 'fleets',
      icon: 'icmn icmn-key2',
      roles: ['admin'],
      url: '/fleets',
    },
  ],
  routes: [
    {
      path: '/fleets',
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/fleets/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: { fleets, fleetDetails, fleetUpdate, fleetCreate },
  sagas: [fleetsSaga(), fleetDetailsSaga(), fleetUpdateSaga(), fleetCreateSaga()],
}

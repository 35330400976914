import { net, getToken } from 'ca-admin-core'
import { API_URL } from 'utils/constants'

const { get, postJson } = net

export const url = `${API_URL}/app-vehicle-types`

const List = params => get(url, params, getToken())
const Update = params => postJson(url, params, getToken())

export default {
  List,
  Update,
}

import { networkActions, extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('vehicleDetails'),
  ...extraNetworkActions('UPDATE', 'vehicleDetails/update'),
}

export default actions

export function load(payload) {
  return {
    type: actions.LOAD,
    payload,
  }
}

export function update(uuid, params) {
  return {
    type: actions.UPDATE,
    payload: {
      uuid,
      params,
    },
  }
}

export function reset() {
  return {
    type: actions.RESET,
  }
}

import { all, takeLatest, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userDetails } = API

function* LOAD({ payload }) {
  yield put(request(userDetails(payload), 'userDetails'))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}

import React from 'react'
import { Tag } from 'antd'

export default class SecurityTag extends React.Component {
  getTag = (ignition, status) => {
    let color
    let ignitionStatus

    switch (ignition) {
      case 'IGNITION_ON':
        ignitionStatus = 'authorized'
        color = status === 'free' ? 'red' : 'green'
        break
      case 'IGNITION_OFF':
        ignitionStatus = 'unauthorized'
        color = ''
        break
      default:
        ignitionStatus = 'n / a'
        color = 'gold'
    }

    return <Tag color={color}>{ignitionStatus}</Tag>
  }

  render() {
    const { data, status } = this.props

    if (!data || !data.alematicsStatus) {
      return <Tag>n / a</Tag>
    }

    const {
      alematicsStatus: { ignition },
    } = data

    return this.getTag(ignition, status)
  }
}

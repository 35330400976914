import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userUpdatePassport } = API

function* UPDATE_PASSPORT({ payload: { id, params } }) {
  yield put(request(userUpdatePassport(id, params), actions.UPDATE_PASSPORT))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Passport / ID updated.',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_PASSPORT, UPDATE_PASSPORT),
    takeLatest(actions.UPDATE_PASSPORT_SUCCESS, SUCCESS),
  ])
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('UPDATE', 'layer/update'),
}

export default actions

export function update(id, params) {
  return {
    type: actions.UPDATE,
    payload: {
      id,
      params,
    },
  }
}

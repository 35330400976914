import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('UPDATE_PASSPORT', 'user/passport/update'),
}

export default actions

export function userUpdatePassportAction(id, params) {
  return {
    type: actions.UPDATE_PASSPORT,
    payload: {
      id,
      params,
    },
  }
}

import React from 'react'
import { Card, Table, Empty, Tag, Button, Row, Col } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import ReactPanZoom from 'react-image-pan-zoom-rotate'

@withRouter
export default class DamageList extends React.Component {
  state = {
    imageUrl: null,
  }

  handleViewPress = imageUrl => {
    this.setState({ imageUrl })
  }

  render() {
    const { ...rest } = this.props
    const { imageUrl } = this.state

    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
        render: type => <Tag>{type}</Tag>,
      },
      {
        title: 'Reporter',
        dataIndex: 'user',
        render: user =>
          (user && (
            <Link
              to={{ pathname: `/users/${user.id}`, state: { modal: true } }}
              className="utils__link--underlined"
            >
              {user.first_name} {user.last_name}
            </Link>
          )) ||
          'n / a',
      },
      {
        title: 'Trip',
        dataIndex: 'trip_uuid',
        render: uuid =>
          (uuid && (
            <Link
              to={{ pathname: `/trips/${uuid}`, state: { modal: true } }}
              className="utils__link--underlined"
            >
              {uuid}
            </Link>
          )) ||
          'n / a',
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        sorter: false,
        render: date =>
          moment
            .utc(date)
            .local()
            .format('YYYY-MM-DD HH:mm'),
      },
      {
        title: 'Actions',
        key: 'action',
        render: record => (
          <Button onClick={() => this.handleViewPress(record.url)} icon="eye">
            View
          </Button>
        ),
      },
    ]

    return (
      <Row gutter={32}>
        <Col xs={24} sm={12} className="mb-5">
          <Card size="small" className="mb-3" style={{ overflow: 'hidden', height: 340 }}>
            {imageUrl ? (
              <ReactPanZoom image={imageUrl} alt="damage" />
            ) : (
              <Empty description="No entry selected" style={{ margin: '80px 0' }} />
            )}
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Table
            key="table"
            className="utils__scrollTable"
            columns={columns}
            pagination={false}
            rowKey="id"
            {...rest}
          />
        </Col>
      </Row>
    )
  }
}

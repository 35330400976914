import React from 'react'
import { Button, Popover } from 'antd'

export default class WarningSign extends React.Component {
  render() {
    const { warnings, warning, ...rest } = this.props
    let content

    if (warnings && warnings.length > 0) {
      content = warnings.map(w => <div key={w.k}>{w.v}</div>)
    } else if (warning) {
      content = warning
    }

    if (!content) {
      return null
    }

    return (
      <Popover placement="topLeft" content={content}>
        <Button type="danger" size="small" icon="warning" ghost {...rest} />
      </Popover>
    )
  }
}

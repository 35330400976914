import { Loadable } from 'ca-admin-core'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import { webURL, webURLCreate } from './constants'

export { webURL, webURLCreate } from './constants'
export { url } from './api'

export default {
  name: 'vehicleTypes',
  menu: [
    {
      title: 'Vehicle types',
      key: 'vehicleTypes',
      icon: 'icmn icmn-price-tag',
      url: webURL,
      roles: ['admin'],
    },
  ],
  routes: [
    {
      path: webURL,
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: webURLCreate,
      component: Loadable(() => import('./pages/create')),
      exact: true,
      modal: true,
    },
    {
      path: `${webURL}/:id`,
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers,
  sagas,
}

import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('appVehicleTypes/update'),
}

const update = payload => {
  return {
    type: actions.LOAD,
    payload,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, update, reset }

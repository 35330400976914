import actions from './actions'

const initialState = {
  loading: false,
  data: null,
  pagination: null,
  create: {},
}

export default function modemsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      }
    case actions.ERROR:
      return {
        ...initialState,
      }
    case actions.RESET:
      return {
        ...initialState,
      }
    case actions.CREATE_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
        },
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
        },
        data: [action.payload.data, ...state.data],
      }
    case actions.CREATE_ERROR:
      return {
        ...state,
        create: {
          ...action.payload,
          loading: false,
        },
      }
    default:
      return state
  }
}

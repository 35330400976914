import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/promocodes/api'
import actions from './actions'

const { promocodeDestroy } = API

function* DESTROY({ payload: { id } }) {
  yield put(request(promocodeDestroy(id), actions.DESTROY))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Promocode deleted.',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.DESTROY, DESTROY), takeLatest(actions.DESTROY_SUCCESS, SUCCESS)])
}

import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('reports/events_app_opened'),
}

const load = payload => ({
  type: actions.LOAD,
  payload,
})

const reset = () => ({ type: actions.RESET })

export { actions, load, reset }

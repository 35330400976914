import actions from './actions'

const initialState = {
  listCC: [],
  loading: false,
}

export default function userListCCReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LIST_CC_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.LIST_CC_SUCCESS:
      return {
        ...state,
        listCC: action.payload.cc,
        loading: false,
      }
    case actions.LIST_CC_ERROR:
      return {
        ...initialState,
        ...action.payload,
      }
    case actions.LIST_CC_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

import { actions } from './actions'

const initialState = {
  loading: false,
  errors: {},
}

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...initialState,
      }
    case actions.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actions.ERROR:
      return {
        ...initialState,
        ...action.payload,
      }
    case actions.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

import { takeEvery, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import { maintenanceDone } from '../../api'
import actions from './actions'

function* DONE({ payload: { id } }) {
  yield put(request(maintenanceDone(id), actions.DONE, id))
}

export default function* rootSaga() {
  yield takeEvery(actions.DONE, DONE)
}

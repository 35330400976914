import React from 'react'
import HeatmapLayer from 'react-leaflet-heatmap-layer'
import moment from 'moment'
import { net } from 'ca-admin-core'

import { VEHICLE_TYPE_CAR } from 'utils/constants'

import { heatmap } from '../api'

const { api } = net

export default class HeatmapDepartures extends React.Component {
  state = {
    points: [],
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps) {
    const { type, start, end } = this.props
    const { type: prevType, start: prevStart, end: prevEnd } = prevProps

    if (type !== prevType || start !== prevStart || end !== prevEnd) {
      this.load()
    }
  }

  load = () => {
    let { start, type } = this.props
    const { end } = this.props

    if (!start) {
      start = moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD')
    }

    if (!type) {
      type = VEHICLE_TYPE_CAR
    }

    const filters = { start, type }

    if (end) {
      filters.end = end
    }

    api(heatmap({ filters }))
      .then(response => response.json())
      .then(json => {
        this.setState({ points: json })
      })
  }

  render() {
    const { points } = this.state

    return (
      <HeatmapLayer
        points={points}
        longitudeExtractor={m => m[0]}
        latitudeExtractor={m => m[1]}
        intensityExtractor={() => 2}
      />
    )
  }
}

/* === URLs === */
import store from 'store'

const { REACT_APP_BASE_URL: BASE_URL } = process.env
const { REACT_APP_FLEET_BASE_URL: FLEET_BASE_URL } = process.env

export const API_V1 = '/api/v1/admin'

export const API_URL = `${BASE_URL}${API_V1}`
export const FLEET_API_URL = `${FLEET_BASE_URL}${API_V1}`

export const ADMIN_ROLES = [
  'admin',
  'operator',
  'bike-operator',
  'fleet-operator',
  'fleet-owner',
  'security-operator',
]

export const APP_LAST_UPDATE = '2020.03.31'

export const VEHICLE_TYPE_CAR = 1
export const VEHICLE_TYPE_BIKE = 2

export const LanguageHeader = () => {
  const lang = store.get('app.settings.locale') || 'en'
  return { 'Accept-Language': lang }
}

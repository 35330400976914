import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('UPDATE_DETAILS', 'user/details/update'),
}

export default actions

export function userUpdateDetailsAction(id, params) {
  return {
    type: actions.UPDATE_DETAILS,
    payload: {
      id,
      params,
    },
  }
}

/* eslint new-cap: 0 */

import React, { createRef } from 'react'
import { Map, Marker, TileLayer, Popup, Circle } from 'react-leaflet'
import FullscreenControl from 'react-leaflet-fullscreen'
import L from 'leaflet'

import 'react-leaflet-fullscreen/dist/styles.css'
import styles from './style.module.scss'

export const pinOptions = {
  className: '',
  iconAnchor: [12, 32],
  popupAnchor: [0, -32],
  tooltipAnchor: [0, -20],
  iconSize: [24, 32],
}

export default class BaseMap extends React.Component {
  state = {
    currentLocation: null,
    radius: 0,
  }

  mapRef = createRef()

  componentDidMount() {
    this.startWatchLocation()
  }

  componentWillUnmount() {
    this.stopWatchLocation()
  }

  startWatchLocation = () => {
    const map = this.mapRef.current

    if (map) {
      map.leafletElement.locate()
    }
  }

  stopWatchLocation = () => {
    const map = this.mapRef.current

    if (map) {
      map.leafletElement.stopLocate()
    }
  }

  handleLocationFound = e => {
    // console.log('location update')
    this.setState({
      currentLocation: e.latlng,
      radius: e.radius,
    })
  }

  getLocationPin = () => {
    return new L.divIcon({
      ...pinOptions,
      html: `<div class="${styles.pin}"></div>`,
    })
  }

  render() {
    const { currentLocation, radius } = this.state
    const { children, ...rest } = this.props
    const center = { lat: 44.43, lng: 26.1 }

    return (
      <Map
        center={center}
        zoom={13}
        maxZoom={22}
        ref={this.mapRef}
        onLocationfound={this.handleLocationFound}
        {...rest}
      >
        <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2l0eWxpbmsiLCJhIjoiY2p1bWFlOTMzMHNiMTQzcHcwOWd3ajdkdyJ9.3S-mnIR0lvcsWq4elpk-TA" />

        <FullscreenControl />

        {currentLocation && <Circle center={currentLocation} fillColor="blue" radius={radius} />}
        {currentLocation && (
          <Marker position={currentLocation} icon={this.getLocationPin()}>
            <Popup>Current location</Popup>
          </Marker>
        )}

        {children}
      </Map>
    )
  }
}

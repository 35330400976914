import React from 'react'
import HeatmapLayer from 'react-leaflet-heatmap-layer'
import moment from 'moment'
import { withLeaflet } from 'react-leaflet'
import { HexbinLayer } from 'react-leaflet-d3'
import { net } from 'ca-admin-core'

import { events } from '../api'

import './hexbin.css'

const { api } = net

const hexbinOptions = {
  colorScaleExtent: [1, undefined],
  radiusScaleExtent: [1, undefined],
  colorRange: ['#FFFFFF', '#7f7fff', '#FFA500', '#ff0000'],
  radiusRange: [4, 12],
  radius: 12,
  opacity: 0.5,
}

export default class HeatmapEvents extends React.Component {
  state = {
    points: [],
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps) {
    const { type, start, end } = this.props
    const { type: prevType, start: prevStart, end: prevEnd } = prevProps

    if (type !== prevType || start !== prevStart || end !== prevEnd) {
      this.load()
    }
  }

  load = () => {
    let { start, type } = this.props
    const { end } = this.props

    if (!start) {
      start = moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD')
    }

    if (!type) {
      type = 'app_opened'
    }

    const filters = { start, type }

    if (end) {
      filters.end = end
    }

    api(events({ filters }))
      .then(response => response.json())
      .then(json => {
        const points = json.map(event => [
          parseFloat(event.properties.lat),
          parseFloat(event.properties.lng),
        ])

        this.setState({ points })
      })
  }

  toGEOJson = points => {
    const geoJSON = {
      type: 'FeatureCollection',
      features: [],
    }

    points.forEach(point => {
      if (point[0] !== 0.0 || point[1] !== 0.0) {
        geoJSON.features.push({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [point[1], point[0]],
          },
        })
      }
    })

    return geoJSON
  }

  render() {
    const { points } = this.state
    const { mode } = this.props

    if (mode === 'hexbin') {
      const WrappedHexbinLayer = withLeaflet(HexbinLayer)
      return <WrappedHexbinLayer data={this.toGEOJson(points)} {...hexbinOptions} />
    }

    return (
      <HeatmapLayer
        points={points}
        latitudeExtractor={m => m[0]}
        longitudeExtractor={m => m[1]}
        intensityExtractor={() => 1}
        blur={6}
        radius={10}
      />
    )
  }
}

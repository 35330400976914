import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('BULK_DELETE', 'maintenance/bulk/delete'),
}

export default actions

export function bulkDelete(payload) {
  return {
    type: actions.BULK_DELETE,
    payload,
  }
}

import React from 'react'
import { Polygon } from 'react-leaflet'
import * as turf from '@turf/turf'
import { net } from 'ca-admin-core'

import { VEHICLE_TYPE_CAR } from 'utils/constants'

import { isochrones } from '../api'

const { api } = net

export default class Isochrone extends React.Component {
  state = {
    data: null,
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props
    const { type: prevType } = prevProps

    if (type !== prevType) {
      this.load()
    }
  }

  processData = json => {
    const data = {
      5: [],
      7: [],
      10: [],
    }

    json.data.forEach(entry => {
      entry.isochrone.features.forEach(feature => {
        const {
          properties: { contour },
        } = feature
        data[contour].push(feature)
      })
    })

    this.setState({ data })
  }

  load = () => {
    const { type = VEHICLE_TYPE_CAR } = this.props

    const filters = { type }

    api(isochrones({ filters }))
      .then(response => response.json())
      .then(json => {
        this.processData(json)
      })
  }

  render() {
    const { data } = this.state
    const { time = 7, polygonColor } = this.props

    if (!data || !data[time] || data[time].length === 0) {
      return null
    }

    const mapData = data[time].map(el => {
      // el = turf.unkinkPolygon(el)
      // el = turf.flip(el)
      return turf.flip(el)
    })

    const union = turf.union(...mapData)

    return (
      <Polygon
        positions={union.geometry.coordinates}
        color={polygonColor || '#ffcc00'}
        weight={1}
      />
    )
  }
}

import React from 'react'
import { Tag } from 'antd'

export default class TripTypeTag extends React.Component {
  getTag = type => {
    let color = 'blue'

    switch (type) {
      case 'bike':
        color = 'geekblue'
        break
      case 'car':
        color = 'purple'
        break
      default:
        color = 'blue'
    }

    return <Tag color={color}>{type || 'n / a'}</Tag>
  }

  render() {
    const { type } = this.props

    return this.getTag(type)
  }
}

import actions from './actions'
import destroyActions from '../destroy/actions'
import statusActions from '../status/actions'
import userTopUpWallet from '../wallet/topup/actions'
import addTripActions from '../addtrip/actions'
import updateDetailsAction from '../updateDetails/actions'
import validateDocument from '../validateDocument/actions'

const initialState = {
  // API get user object
  user: {},
  audit: [],
  files: [],
  documents: [],
  trips: [],
  transactions: [],
  wallet: {},
  promocodes: [],
  // Extra stuff ...not sure if needed yet
  loading: false,
}

export default function userDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...initialState,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case actions.ERROR:
      return {
        ...initialState,
      }
    case actions.RESET:
      return {
        ...initialState,
      }
    case validateDocument.VALIDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        files: action.payload.files,
      }
    case updateDetailsAction.UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case addTripActions.ADD_TRIP_SUCCESS:
      return {
        ...state,
        trips: [action.payload.trip, ...state.trips],
      }
    case userTopUpWallet.TOPUP_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.payload.wallet,
        transactions: [action.payload.transactions, ...state.transactions],
      }
    case statusActions.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      }
    case destroyActions.DESTROY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

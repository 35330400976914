import { Loadable } from 'ca-admin-core'
import reducers from './redux/reducers'
import sagas from './redux/sagas'

export default {
  name: 'mobileApp',
  menu: [
    {
      title: 'Mobile app',
      key: 'mobileApp',
      icon: 'icmn icmn-users',
      children: [
        {
          title: 'Vehicle types',
          key: 'appVehicleTypes',
          url: '/app/vehicle-types',
        },
      ],
      roles: ['admin'],
    },
  ],
  routes: [
    {
      path: '/app/vehicle-types',
      component: Loadable(() => import('./pages')),
      exact: true,
    },
  ],
  reducers,
  sagas,
}

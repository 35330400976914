import React from 'react'
import { Icon, Progress, Tooltip } from 'antd'

import { VEHICLE_TYPE_CAR } from 'utils/constants'

import { getColor } from '../VehiclesLayer/utils'

const scoreFormat = (icon, tooltip) => () => (
  <Tooltip title={tooltip}>
    <Icon type={icon} />
  </Tooltip>
)

export const VehicleScoreOverall = props => {
  const { vehicle } = props

  return (
    <>
      {vehicle.score.overall} / 100 score
      <Icon type="info-circle" className="ml-2" />
    </>
  )
}

const VehicleScore = props => {
  const { vehicle } = props

  return (
    <>
      <Progress
        percent={vehicle.score.voltage}
        size="small"
        strokeColor={getColor(vehicle, vehicle.score.voltage)}
        format={scoreFormat('thunderbolt', 'Voltage')}
        status="normal"
      />
      {vehicle.type_id === VEHICLE_TYPE_CAR && (
        <Progress
          percent={vehicle.score.fuel}
          size="small"
          strokeColor={getColor(vehicle, vehicle.score.fuel)}
          format={scoreFormat('car', 'Fuel')}
          status="normal"
        />
      )}
      {vehicle.type_id === VEHICLE_TYPE_CAR && (
        <Progress
          percent={vehicle.score.sattellites}
          size="small"
          strokeColor={getColor(vehicle, vehicle.score.sattellites)}
          format={scoreFormat('environment', 'GPS')}
          status="normal"
        />
      )}
      <Progress
        percent={vehicle.score.alerts}
        size="small"
        strokeColor={getColor(vehicle, vehicle.score.alerts)}
        format={scoreFormat('warning', 'Alerts')}
        status="normal"
      />
      <Progress
        percent={vehicle.score.data_transmission}
        size="small"
        strokeColor={getColor(vehicle, vehicle.score.data_transmission)}
        format={scoreFormat('cloud-sync', 'Connection')}
        status="normal"
      />
    </>
  )
}

export default VehicleScore

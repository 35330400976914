import { all, takeLatest, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import API from 'modules/alerts/api'
import actions from './actions'

const { alertsBikes } = API

function* LOAD({ payload }) {
  yield put(request(alertsBikes(payload), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}

export const parseOmniSerial = serial => {
  const regex = /(.*)app.aperider.com:8088\/ApeRider\/download\/app\?idt=(.*)&No=(.*)/
  const match = serial.match(regex)
  return (match && match[3]) || serial
}

export const parseConcoxSerial = serial => {
  return serial
}

export const parseSerial = serial => {
  serial = parseOmniSerial(serial)
  serial = parseConcoxSerial(serial)

  return serial
}

import vehicleTypes from './list/sagas'
import vehicleTypeDetails from './show/sagas'
import vehicleTypeCreate from './create/sagas'
import vehicleTypeUpdate from './update/sagas'
import vehicleTypeDestroy from './destroy/sagas'

export default [
  vehicleTypes(),
  vehicleTypeDetails(),
  vehicleTypeCreate(),
  vehicleTypeUpdate(),
  vehicleTypeDestroy(),
]

import pricings from './list/reducers'
import pricingDetails from './show/reducers'
import pricingUpdate from './update/reducers'
import pricingCreate from './create/reducers'

export default {
  pricings,
  pricingDetails,
  pricingUpdate,
  pricingCreate,
}

import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('layer/details'),
}

export default actions

export function load(id) {
  return {
    type: actions.LOAD,
    payload: {
      id,
    },
  }
}

export function reset() {
  return {
    type: actions.RESET,
  }
}

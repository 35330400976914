import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('CREATE', 'fleet/create'),
}

export default actions

export function create(params) {
  return {
    type: actions.CREATE,
    payload: {
      params,
    },
  }
}

import { Loadable } from 'ca-admin-core'

import sparkIncome from './redux/income/reducers'
import sparkIncomeSaga from './redux/income/sagas'

export { default as HeatmapDepartures } from './components/HeatmapDepartures'

export default {
  name: 'competition',
  menu: [
    {
      title: 'Competition',
      key: 'competition',
      icon: 'icmn icmn-office',
      roles: ['admin'],
      children: [
        {
          title: 'Spark',
          key: 'competition-spark',
          children: [
            {
              title: 'Income & usage',
              key: 'competition-spark-income-usage',
              url: '/competition/spark/income-usage',
            },
            {
              title: 'Routes',
              key: 'competition-spark-routes',
              url: '/competition/spark/routes',
            },
            {
              title: 'Departures heatmap',
              key: 'competition-spark-heatmap-departures',
              url: '/competition/spark/heatmap-departures',
            },
          ],
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/competition/spark/income-usage'],
      component: Loadable(() => import('./pages/income')),
      exact: true,
    },
    {
      path: ['/competition/spark/routes'],
      component: Loadable(() => import('./pages/routes')),
      exact: true,
    },
    {
      path: ['/competition/spark/heatmap-departures'],
      component: Loadable(() => import('./pages/heatmap')),
      exact: true,
    },
  ],
  reducers: { sparkIncome },
  sagas: [sparkIncomeSaga()],
}

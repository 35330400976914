import React from 'react'
import { Tag } from 'antd'

export default class TripStatusTag extends React.Component {
  getTag = (status, text) => {
    let color = 'purple'

    switch (status) {
      case 'open':
      case 'opened':
      case 'started':
        color = 'green'
        break
      case 'close':
      case 'closed':
      case 'ended':
        color = ''
        break
      default:
        color = 'gold'
    }

    return <Tag color={color}>{text || status || 'n / a'}</Tag>
  }

  render() {
    const { status, text } = this.props

    return this.getTag(status, text)
  }
}

import { Loadable } from 'ca-admin-core'

import feedback from './redux/list/reducers'
import feedbackDetails from './redux/details/reducers'

import feedbackSaga from './redux/list/sagas'
import feedbackDetailsSaga from './redux/details/sagas'

export default {
  name: 'feedback',
  menu: [
    {
      title: 'App feedback',
      key: 'feedback',
      url: '/feedback',
      icon: 'icmn icmn-bubble',
      roles: ['admin'],
    },
  ],
  routes: [
    {
      path: '/feedback',
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/feedback/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: { feedback, feedbackDetails },
  sagas: [feedbackSaga(), feedbackDetailsSaga()],
}

import { Loadable } from 'ca-admin-core'

import damages from 'modules/damages/redux/list/reducers'
import damageDetails from 'modules/damages/redux/details/reducers'

import damagesSaga from 'modules/damages/redux/list/sagas'
import damageDetailsSaga from 'modules/damages/redux/details/sagas'

export { default as List } from './shared/List'

export default {
  name: 'damages',
  menu: [
    {
      title: 'Damage reports',
      key: 'damages',
      icon: 'icmn icmn-warning',
      roles: ['admin', 'operator', 'fleet-operator', 'bike-operator'],
      children: [
        {
          title: 'All',
          key: 'damages',
          url: '/damages',
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/damages/filter/:filter', '/damages'],
      component: Loadable(() => import('modules/damages/pages')),
      exact: true,
    },
    {
      path: '/damages/:id',
      component: Loadable(() => import('modules/damages/pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    damages,
    damageDetails,
  },
  sagas: [damagesSaga(), damageDetailsSaga()],
}

import { all, takeLatest, takeEvery, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import { modems, createModem } from '../../api'
import actions from './actions'

function* LOAD({ payload }) {
  yield put(request(modems(payload), 'modems'))
}

function* CREATE({ payload }) {
  yield put(request(createModem(payload), 'modems/create'))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeEvery(actions.CREATE, CREATE)])
}

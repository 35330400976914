import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('CREATE', 'promocode/create'),
}

export default actions

export function create(payload) {
  return {
    type: actions.CREATE,
    payload,
  }
}

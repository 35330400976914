import { Loadable } from 'ca-admin-core'

import income from './redux/income/reducers'
import incomeSaga from './redux/income/sagas'

import topup from './redux/topup/reducers'
import topupSaga from './redux/topup/sagas'

import city from './redux/city/reducers'
import citySaga from './redux/city/sagas'

import unused from './redux/unused/reducers'
import unusedSaga from './redux/unused/sagas'

import used from './redux/used/reducers'
import usedSaga from './redux/used/sagas'

import partners from './redux/partners/reducers'
import partnersSaga from './redux/partners/sagas'

import eventsAppOpened from './redux/events_app_opened/reducers'
import eventsAppOpenedSaga from './redux/events_app_opened/sagas'

export { default as HeatmapDepartures } from './components/HeatmapDepartures'
export { default as HeatmapEvents } from './components/HeatmapEvents'
export { default as Isochrone } from './components/Isochrone'

export default {
  name: 'reports',
  menu: [
    {
      title: 'Reports',
      key: 'reports',
      icon: 'icmn icmn-pie-chart',
      roles: ['admin', 'fleet-owner'],
      children: [
        {
          title: 'Income & usage',
          key: 'reports-income-usage',
          url: '/reports/income-usage',
        },
        {
          title: 'Topup',
          key: 'topup',
          url: '/reports/topup',
          roles: ['admin'],
        },
        {
          title: 'City',
          key: 'city',
          url: '/reports/city',
          roles: ['admin'],
        },
        {
          title: 'Unused vehicles',
          key: 'reports-unused-vehicles',
          url: '/reports/unused-vehicles',
          roles: ['admin'],
        },
        {
          title: 'Vehicles usage',
          key: 'reports-vehicles-usage',
          url: '/reports/vehicles-usage',
        },
        {
          title: 'Routes',
          key: 'reports-routes',
          url: '/reports/routes',
        },
        {
          title: 'Events heatmap',
          key: 'reports-events',
          url: '/reports/events',
          roles: ['admin'],
        },
        {
          title: 'Isochrone',
          key: 'isochrone',
          url: '/reports/isochrone',
          roles: ['admin'],
        },
        {
          title: 'Departures heatmap',
          key: 'reports-heatmap-departures',
          url: '/reports/heatmap-departures',
          roles: ['admin'],
        },
        {
          title: 'Partners',
          key: 'reports-partners',
          url: '/reports/partners',
          roles: ['admin', 'fleet-owner'],
        },
        {
          title: 'Events app opened',
          key: 'reports-events-app-opened',
          url: '/reports/events-app-opened',
          roles: ['admin'],
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/reports/income-usage'],
      component: Loadable(() => import('./pages/income')),
      exact: true,
    },
    {
      path: ['/reports/topup'],
      component: Loadable(() => import('./pages/topup')),
      exact: true,
    },
    {
      path: ['/reports/city'],
      component: Loadable(() => import('./pages/city')),
      exact: true,
    },
    {
      path: ['/reports/unused-vehicles'],
      component: Loadable(() => import('./pages/unused')),
      exact: true,
    },
    {
      path: ['/reports/vehicles-usage'],
      component: Loadable(() => import('./pages/used')),
      exact: true,
    },
    {
      path: ['/reports/routes'],
      component: Loadable(() => import('./pages/routes')),
      exact: true,
    },
    {
      path: ['/reports/events'],
      component: Loadable(() => import('./pages/events')),
      exact: true,
    },
    {
      path: '/reports/isochrone',
      component: Loadable(() => import('./pages/isochrone')),
      url: '/reports/isochrone',
    },
    {
      path: ['/reports/heatmap-departures'],
      component: Loadable(() => import('./pages/heatmap')),
      exact: true,
    },
    {
      path: '/reports/partners',
      component: Loadable(() => import('./pages/partners')),
      exact: true,
    },
    {
      path: '/reports/events-app-opened',
      component: Loadable(() => import('./pages/events_app_opened')),
      exact: true,
    },
  ],
  reducers: {
    income,
    unused,
    used,
    topup,
    city,
    partners,
    eventsAppOpened,
  },
  sagas: [
    incomeSaga(),
    unusedSaga(),
    usedSaga(),
    topupSaga(),
    citySaga(),
    partnersSaga(),
    eventsAppOpenedSaga(),
  ],
}

import { all, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/trips/api'
import actions from './actions'

const { startTrip } = API

function* START_TRIP({ payload: { id } }) {
  yield put(request(startTrip(id), actions.START_TRIP))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Trip started!',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.START_TRIP, START_TRIP),
    takeEvery(actions.START_TRIP_SUCCESS, SUCCESS),
  ])
}

import React from 'react'
import { Tag } from 'antd'

export default class IgnitionTag extends React.Component {
  getTag = (ignitionOn, status) => {
    let color
    let ignitionStatus

    if (ignitionOn) {
      ignitionStatus = 'on'
      color = status === 'free' ? 'red' : 'green'
    } else {
      ignitionStatus = 'off'
      color = ''
    }

    return <Tag color={color}>{ignitionStatus}</Tag>
  }

  render() {
    const { data, status } = this.props

    if (!data || !data.alematicsStatus) {
      return <Tag>n / a</Tag>
    }

    let {
      alematicsStatus: { mainPower },
    } = data
    mainPower = parseFloat(mainPower)

    const ignitionOn = mainPower > 13.5

    return this.getTag(ignitionOn, status)
  }
}

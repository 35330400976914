import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('UPDATE_STATUS', 'user/status'),
}

export default actions

export function userUpdateStatus(id, params) {
  return {
    type: actions.UPDATE_STATUS,
    payload: {
      id,
      params,
    },
  }
}

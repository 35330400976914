import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/promocodes/api'
import actions from './actions'

const { promocodeCreate } = API

function* CREATE({ payload }) {
  yield put(request(promocodeCreate(payload), actions.CREATE))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Promo code created.',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE, CREATE), takeLatest(actions.CREATE_SUCCESS, SUCCESS)])
}

import { Loadable } from 'ca-admin-core'

import metadata from './redux/metadata/reducers'
import metadataSaga from './redux/metadata/sagas'

import vehicles from './redux/list/reducers'
import vehiclesSaga from './redux/list/sagas'

import vehicleDetails from './redux/details/reducers'
import vehicleDetailsSaga from './redux/details/sagas'

import vehicleUpdate from './redux/update/reducers'
import vehicleUpdateSaga from './redux/update/sagas'

import vehicleHistory from './redux/history/reducers'
import vehicleHistorySaga from './redux/history/sagas'

import vehicleHistoryRoute from './redux/route/reducers'
import vehicleHistoryRouteSaga from './redux/route/sagas'

import vehicleAssignModem from './redux/assignModem/reducers'
import vehicleAssignModemSaga from './redux/assignModem/sagas'

import vehicleAssignCanbox from './redux/assignCanbox/reducers'
import vehicleAssignCanboxSaga from './redux/assignCanbox/sagas'

import vehicleMaintenance from './redux/maintenance/reducers'
import vehicleMaintenanceSaga from './redux/maintenance/sagas'

import vehicleDamage from './redux/damage/reducers'
import vehicleDamageSaga from './redux/damage/sagas'

import vehicleClearAlerts from './redux/clearAlerts/reducers'
import vehicleClearAlertsSaga from './redux/clearAlerts/sagas'

export { assignToPOI, vehiclesMap, vehicleFindBy } from './api'
export * from './components/tags/cars'
export * from './components/tags/bikes'

export { webUrl } from './constants'

export default {
  name: 'vehicles',
  menu: [
    {
      title: 'Vehicles',
      key: 'vehicles',
      icon: 'icmn icmn-rocket',
      roles: ['admin', 'operator', 'fleet-operator', 'bike-operator', 'fleet-owner'],
      children: [
        {
          title: 'All',
          key: 'vehicles',
          url: '/vehicles',
          roles: ['admin', 'operator', 'fleet-operator', 'bike-operator', 'fleet-owner'],
        },
        {
          title: 'Bikes',
          key: 'vehicles-bikes',
          url: '/vehicles/filter/bikes',
          roles: ['admin', 'operator', 'fleet-operator', 'bike-operator'],
        },
        {
          title: 'Cars',
          key: 'vehicles-cars',
          url: '/vehicles/filter/cars',
          roles: ['admin', 'operator', 'fleet-operator', 'bike-operator'],
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/vehicles/filter/:filter?', '/vehicles'],
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/vehicles/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    metadata,
    vehicles,
    vehicleDetails,
    vehicleUpdate,
    vehicleHistory,
    vehicleHistoryRoute,
    vehicleAssignModem,
    vehicleAssignCanbox,
    vehicleMaintenance,
    vehicleDamage,
    vehicleClearAlerts,
  },
  sagas: [
    metadataSaga(),
    vehiclesSaga(),
    vehicleDetailsSaga(),
    vehicleUpdateSaga(),
    vehicleHistorySaga(),
    vehicleHistoryRouteSaga(),
    vehicleAssignModemSaga(),
    vehicleAssignCanboxSaga(),
    vehicleMaintenanceSaga(),
    vehicleDamageSaga(),
    vehicleClearAlertsSaga(),
  ],
}

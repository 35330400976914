import { all, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/trips/api'
import actions from './actions'

const { endTrip } = API

function* END_TRIP({ payload: { id } }) {
  yield put(request(endTrip(id), actions.END_TRIP))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Trip ended!',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.END_TRIP, END_TRIP), takeEvery(actions.END_TRIP_SUCCESS, SUCCESS)])
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('END_TRIP', 'trip/end'),
}

export default actions

export function end(id, params) {
  return {
    type: actions.END_TRIP,
    payload: {
      id,
      params,
    },
  }
}

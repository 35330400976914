import canboxes from './canboxes'
import modems from './modems'

export default {
  name: 'equipment',
  menu: [
    {
      title: 'Equipment',
      key: 'equipment',
      icon: 'icmn icmn-drive',
      roles: ['admin'],
      children: [...canboxes.menu, ...modems.menu],
    },
  ],
  routes: [...canboxes.routes, ...modems.routes],
  reducers: {
    ...canboxes.reducers,
    ...modems.reducers,
  },
  sagas: [...canboxes.sagas, ...modems.sagas],
}

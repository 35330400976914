import React from 'react'
import { Tag } from 'antd'

export default class CarStatusTag extends React.Component {
  getTag = status => {
    let color

    switch (status) {
      case 'occupied':
        color = 'green'
        break
      case 'free':
        color = ''
        break
      default:
        color = 'gold'
    }

    return <Tag color={color}>{status || 'n / a'}</Tag>
  }

  render() {
    const { status } = this.props

    return this.getTag(status)
  }
}

import { net, getToken } from 'ca-admin-core'
import CONSTANTS from './constants'

const { API } = CONSTANTS
const { get } = net

const damages = params => get(API.DAMAGES, params, getToken())
const damageDetails = id => get(`${API.DAMAGES}/${id}`, null, getToken())

export default {
  damages,
  damageDetails,
}

import { Loadable } from 'ca-admin-core'

import layers from './redux/list/reducers'
import layersSaga from './redux/list/sagas'

import layerDetails from './redux/details/reducers'
import layerDetailsSaga from './redux/details/sagas'

import layerUpdate from './redux/update/reducers'
import layerUpdateSaga from './redux/update/sagas'

import layerCreate from './redux/create/reducers'
import layerCreateSaga from './redux/create/sagas'

export default {
  name: 'layers',
  menu: [
    {
      title: 'Layers',
      key: 'layers',
      icon: 'icmn icmn-stack',
      roles: ['admin'],
      url: '/layers',
    },
  ],
  routes: [
    {
      path: '/layers',
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/layers/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: { layers, layerDetails, layerUpdate, layerCreate },
  sagas: [layersSaga(), layerDetailsSaga(), layerUpdateSaga(), layerCreateSaga()],
}

import { all, takeEvery, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import { assignModem } from '../../api'
import actions from './actions'

function* ASSIGN({ payload: { uuid, params } }) {
  yield put(request(assignModem(uuid, params), actions.ASSIGN))
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ASSIGN, ASSIGN)])
}

import { networkActions } from 'ca-admin-core'

const actions = {
  LOAD: 'promocodeDetails/load',
  ...networkActions('promocodeDetails'),
}

export default actions

export function load(payload) {
  return {
    type: actions.LOAD,
    payload,
  }
}

export function reset() {
  return {
    type: actions.RESET,
  }
}

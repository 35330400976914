import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('metadata'),
}

export default actions

export function load() {
  return {
    type: actions.LOAD,
  }
}

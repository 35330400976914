import { networkActions, extraNetworkActions } from 'ca-admin-core'

const actions = {
  LOAD: 'modems/load',
  ...networkActions('modems'),
  ...extraNetworkActions('CREATE', 'modems/create'),
}

export default actions

export function load(payload) {
  return {
    type: actions.LOAD,
    payload,
  }
}

export function create(payload) {
  return {
    type: actions.CREATE,
    payload,
  }
}

import actions from './actions'

const initialState = {
  loading: false,
}

export default function userRoles(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.UPDATE_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actions.UPDATE_ROLES_ERROR:
      return {
        ...initialState,
      }
    case actions.UPDATE_ROLES_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

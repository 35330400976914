import { all, takeLatest, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userTopupWallet } = API

function* TOPUP_WALLET({ payload: { id, params } }) {
  yield put(request(userTopupWallet(id, params), actions.TOPUP_WALLET))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Wallet successfully updated.',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.TOPUP_WALLET, TOPUP_WALLET),
    takeLatest(actions.TOPUP_WALLET_SUCCESS, SUCCESS),
  ])
}

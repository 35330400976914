import { Loadable } from 'ca-admin-core'

export default {
  name: 'dashboard',
  menu: [
    {
      title: 'Dashboard',
      key: 'dashboard',
      url: '/dashboard',
      icon: 'icmn icmn-home',
      roles: [
        'admin',
        'operator',
        'fleet-operator',
        'bike-operator',
        'security-operator',
        'fleet-owner',
      ],
    },
    {
      divider: true,
    },
  ],
  routes: [
    {
      path: '/dashboard',
      component: Loadable(() => import('modules/dashboard/pages')),
      exact: true,
    },
  ],
  reducers: {},
  sagas: [],
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('RESETPASSWORD', 'user/resetPassword'),
}

export default actions

export function resetPassword(email) {
  return {
    type: actions.RESETPASSWORD,
    email,
  }
}

import { Loadable } from 'ca-admin-core'

import modems from './redux/list/reducers'
import modemsSaga from './redux/list/sagas'

import modemDetails from './redux/details/reducers'
import modemDetailsSaga from './redux/details/sagas'

export default {
  name: 'modems',
  menu: [
    {
      title: 'Modem',
      key: 'equipment-modem',
      url: '/equipment/modem',
    },
  ],
  routes: [
    {
      path: ['/equipment/modem/filter/:filter?', '/equipment/modem'],
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/equipment/modem/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    modems,
    modemDetails,
  },
  sagas: [modemsSaga(), modemDetailsSaga()],
}

export { default as VehicleControls } from './shared/VehicleControls'
export * from './shared/ScanUtils'

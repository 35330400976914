import { API_V1 } from 'utils/constants'

const { REACT_APP_BASE_URL } = process.env

export const webUrl = '/users'

export default {
  API: {
    USERS: `${REACT_APP_BASE_URL}${API_V1}/users`,
    RESETPASSWORD: `${REACT_APP_BASE_URL}/api/v1/auth/resetpassword`,
    DOCUMENTS: `${REACT_APP_BASE_URL}${API_V1}/documents`,
  },
}

import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('pricings/show'),
}

const show = payload => {
  return {
    type: actions.LOAD,
    payload,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, show, reset }

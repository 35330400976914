import { all, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import { vehicleUpdate } from '../../api'
import actions from './actions'

function* UPDATE({ payload: { uuid, params } }) {
  yield put(request(vehicleUpdate(uuid, params), actions.UPDATE))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Vehicle successfully updated.',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPDATE, UPDATE), takeLatest(actions.UPDATE_SUCCESS, SUCCESS)])
}

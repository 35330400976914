/* eslint new-cap: 0 */

import classnames from 'classnames'
import L from 'leaflet'

import { VEHICLE_TYPE_BIKE } from 'utils/constants'

import styles from './style.module.scss'
import { pinOptions } from '../BaseMap'

export const getPinColor = color => {
  switch (color) {
    case 'red':
      return styles.pinRed
    case 'orange':
      return styles.pinOrange
    case 'green':
    default:
      return styles.pinGreen
  }
}

export const getPinOutline = (vehicle, score) => {
  const { status } = vehicle

  if (!score) {
    score = vehicle.score.overall
  }

  if (score === 0) {
    return styles.pinAbnormal
  }

  switch (status) {
    case 'maintenance':
    case 'service':
      return styles.pinMaintenance
    case 'occupied':
      return styles.pinReserved
    default:
      return null
  }
}

export const getPin = (vehicle, score) => {
  const outline = getPinOutline(vehicle, score)
  const color = getPinColor(getColor(vehicle, score))

  return new L.divIcon({
    ...pinOptions,
    html: `<div class="${classnames(
      styles.pin,
      { [styles.pinBike]: vehicle.type_id === VEHICLE_TYPE_BIKE },
      { [styles.pinBikePegas]: vehicle.model_id === 5 },
      outline,
      color,
    )}"></div>`,
  })
}

export const getColor = (vehicle, score) => {
  const { type_id: vehicleType } = vehicle

  if (!score) {
    score = vehicle.score.overall
  }

  // bikes
  if (vehicleType === VEHICLE_TYPE_BIKE) {
    if (score < 30) {
      return 'red'
    }

    if (score >= 30 && score < 50) {
      return 'orange'
    }

    return 'green'
  }

  // cars
  if (score < 10) {
    return 'red'
  }

  if (score >= 10 && score < 40) {
    return 'orange'
  }

  return 'green'
}

import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { listItems } from 'utils/metadata'
import { Form, net } from 'ca-admin-core'
import { getUsers } from 'modules/users'

import { VEHICLE_TYPE_CAR, VEHICLE_TYPE_BIKE } from 'utils/constants'

const TAG_TYPE_DOCUMENT = 1
const TAG_TYPE_SERVICE = 2
const TAG_TYPE_TYRE = 3
const TAG_TYPE_MAINTENANCE = 4

const { api } = net

@connect(({ metadata }) => ({ metadata }))
export default class FormMaintenance extends React.Component {
  state = {
    maintenanceType: [],
    users: [
      {
        text: 'Unassigned',
        value: null,
      },
    ],
  }

  componentDidMount() {
    this.loadUsers()
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props
    if (prevProps.values !== values) {
      const { tag } = values
      this.initLists(tag)
    }
  }

  initLists = tag => {
    const { metadata } = this.props

    switch (tag) {
      case TAG_TYPE_DOCUMENT:
        this.setState({ maintenanceType: listItems(metadata.maintenanceTypeDocuments) })
        break
      case TAG_TYPE_SERVICE:
        this.setState({ maintenanceType: listItems(metadata.maintenanceTypeServices) })
        break
      case TAG_TYPE_TYRE:
        this.setState({ maintenanceType: listItems(metadata.maintenanceTypeWheels) })
        break
      case TAG_TYPE_MAINTENANCE:
        this.setState({ maintenanceType: listItems(metadata.maintenanceTypeMaintenance) })
        break
      default:
        this.setState({ maintenanceType: [] })
        break
    }
  }

  handleOnChange = (field, values, setFieldsValue) => {
    const { tag } = values

    this.initLists(tag)

    if (field === 'tag') {
      setFieldsValue({
        type: null,
      })
    }
  }

  loadUsers = () => {
    const params = {
      filters: {
        roles: ['admin', 'bike-operator', 'fleet-operator'],
      },
    }

    api(getUsers(params))
      .then(response => response.json())
      .then(json => {
        const users = json.data.map(user => ({
          text: `${user.first_name} ${user.last_name}`,
          value: user.id,
        }))

        this.setState({
          users: [
            {
              text: 'Unassigned',
              value: null,
            },
            ...users,
          ],
        })
      })
  }

  render() {
    // mode can be one of the following: null (default), bulk-create, bulk-delete
    const { metadata, mode } = this.props
    const { maintenanceType, users } = this.state

    const configMaintenance = [
      {
        label: 'Vehicle type',
        field: 'vehicle_type',
        type: 'select',
        placeholder: 'Select vehicle type',
        options: [
          { text: 'Car', value: VEHICLE_TYPE_CAR },
          { text: 'Bike', value: VEHICLE_TYPE_BIKE },
          // { text: 'Test', value: 3 }
        ],
        hidden: !mode,
      },
      {
        label: 'Model',
        field: 'model_id',
        type: 'select',
        placeholder: 'Select vehicle model',
        options: listItems(metadata.carModel),
        hidden: !mode,
      },
      {
        label: 'Tag',
        field: 'tag',
        type: 'select',
        placeholder: 'Select tag',
        options: listItems(metadata.maintenanceTag),
      },
      {
        label: 'Type',
        field: 'type',
        type: 'select',
        placeholder: 'Select type',
        options: maintenanceType,
      },
      {
        label: 'Notes',
        field: 'notes',
        placeholder: 'Notes',
        type: 'textarea',
        hidden: mode === 'bulk-delete',
      },
      {
        label: 'Date',
        field: 'operated_at',
        placeholder: 'Current date',
        type: 'date',
        initialValue: moment(),
        hidden: mode === 'bulk-delete',
      },
      {
        label: 'Future task',
        field: 'pending',
        placeholder: 'to do',
        type: 'checkbox',
        hidden: !!mode,
      },
      {
        label: 'Assign to',
        field: 'assigned_to',
        type: 'select',
        placeholder: 'Select user',
        options: users,
        hidden: mode === 'bulk-delete',
      },
      {
        label: 'Expires',
        field: 'expire_date',
        placeholder: 'Expire date',
        type: 'date',
        hidden: mode === 'bulk-delete',
      },
      {
        label: 'Current mileage',
        field: 'operated_km',
        placeholder: 'Km',
        hidden: !!mode,
      },
      {
        label: 'Mileage limit',
        field: 'expire_km',
        placeholder: 'Km',
        hidden: !!mode,
      },
      // {
      //   label: 'Operator',
      //   field: 'operated_by',
      //   placeholder: 'Operator name',
      // },
    ]

    return <Form config={configMaintenance} onChange={this.handleOnChange} {...this.props} />
  }
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('START_TRIP', 'trip/start'),
}

export default actions

export function start(id, params) {
  return {
    type: actions.START_TRIP,
    payload: {
      id,
      params,
    },
  }
}

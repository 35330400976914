import { Loadable } from 'ca-admin-core'

import users from 'modules/users/redux/list/reducers'
import userDetails from 'modules/users/redux/details/reducers'
import userUpdateDetails from 'modules/users/redux/updateDetails/reducers'
import userUpdatePassport from 'modules/users/redux/updatePassport/reducers'
import userUpdateLicense from 'modules/users/redux/updateLicense/reducers'
import userValidateDocument from 'modules/users/redux/validateDocument/reducers'
import userDestroy from 'modules/users/redux/destroy/reducers'
import userResetPassword from 'modules/users/redux/resetpassword/reducers'
import userRoles from 'modules/users/redux/changeroles/reducers'
import userStatus from 'modules/users/redux/status/reducers'
import userAddTrip from 'modules/users/redux/addtrip/reducers'
import userTopUpWallet from 'modules/users/redux/wallet/topup/reducers'
import userListCC from 'modules/users/redux/wallet/listCC/reducers'

import usersSaga from 'modules/users/redux/list/sagas'
import userDetailsSaga from 'modules/users/redux/details/sagas'
import userUpdateDetailsSaga from 'modules/users/redux/updateDetails/sagas'
import userUpdatePassportSaga from 'modules/users/redux/updatePassport/sagas'
import userUpdateLicenseSaga from 'modules/users/redux/updateLicense/sagas'
import userValidateDocumentSaga from 'modules/users/redux/validateDocument/sagas'
import userDestroySaga from 'modules/users/redux/destroy/sagas'
import userResetPasswordSaga from 'modules/users/redux/resetpassword/sagas'
import userRolesSaga from 'modules/users/redux/changeroles/sagas'
import userStatusSaga from 'modules/users/redux/status/sagas'
import userAddTripSaga from 'modules/users/redux/addtrip/sagas'
import userTopUpWalletSaga from 'modules/users/redux/wallet/topup/sagas'
import userListCCSaga from 'modules/users/redux/wallet/listCC/sagas'

export { users as getUsers, scanQR, uploadImage } from './api'

export { webUrl } from './constants'

export default {
  name: 'users',
  menu: [
    {
      title: 'Users',
      key: 'users',
      icon: 'icmn icmn-users',
      roles: ['admin', 'operator'],
      children: [
        {
          title: 'All',
          key: 'users',
          url: '/users',
        },
        {
          title: 'Pending activation',
          key: 'users-pending-activation',
          url: '/users/filter/pending-activation',
        },
        {
          title: 'Negative balance',
          key: 'users-negative-balance',
          url: '/users/filter/negative-balance',
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/users/filter/:filter?', '/users'],
      component: Loadable(() => import('modules/users/pages')),
      exact: true,
    },
    {
      path: '/users/:id',
      component: Loadable(() => import('modules/users/pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    users,
    userDetails,
    userUpdateDetails,
    userUpdatePassport,
    userUpdateLicense,
    userValidateDocument,
    userDestroy,
    userResetPassword,
    userRoles,
    userStatus,
    userAddTrip,
    userTopUpWallet,
    userListCC,
  },
  sagas: [
    usersSaga(),
    userDetailsSaga(),
    userUpdateDetailsSaga(),
    userUpdatePassportSaga(),
    userUpdateLicenseSaga(),
    userValidateDocumentSaga(),
    userDestroySaga(),
    userResetPasswordSaga(),
    userRolesSaga(),
    userStatusSaga(),
    userAddTripSaga(),
    userTopUpWalletSaga(),
    userListCCSaga(),
  ],
}

import React, { Component } from 'react'
import { Admin } from 'ca-admin-core'

import modules from 'modules'

export default class App extends Component {
  render() {
    return <Admin title="Citylink" modules={modules} />
  }
}

import actions from './actions'

const initialState = {
  loading: false,
  data: null,
}

export default function vehicleAssignModem(state = initialState, action) {
  switch (action.type) {
    case actions.ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.ASSIGN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      }
    case actions.ASSIGN_ERROR:
      return {
        ...initialState,
      }
    case actions.ASSIGN_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

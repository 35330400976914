import React from 'react'
import { Tag } from 'antd'

export default class DoorsTag extends React.Component {
  getTag = (doorsLocked, status) => {
    let color
    let doorsLockedStatus

    switch (doorsLocked) {
      case false:
        doorsLockedStatus = 'unlocked'
        color = status === 'free' ? 'red' : ''
        break
      case true:
        doorsLockedStatus = 'locked'
        color = ''
        break
      default:
        doorsLockedStatus = 'n / a'
        color = 'red'
    }

    return (
      <Tag key="doors-locked" color={color}>
        {doorsLockedStatus}
      </Tag>
    )
  }

  render() {
    const { data, status } = this.props
    const tags = []

    if (!data || !data.cangoStatus || data.cangoStatus.length === 0) {
      return <Tag>n / a</Tag>
    }

    const {
      cangoStatus: { doorsOpened, doorsLocked },
    } = data

    tags.push(this.getTag(doorsLocked, status))

    Object.entries(doorsOpened).forEach(([key, value]) => {
      if (value) {
        // door open tag
        tags.push(
          <Tag key={key} color="red">
            {key}
          </Tag>,
        )
      }
    })

    return tags
  }
}

import { Loadable } from 'ca-admin-core'

import maintenance from './redux/list/reducers'
import maintenanceSaga from './redux/list/sagas'

import maintenanceDetails from './redux/details/reducers'
import maintenanceDetailsSaga from './redux/details/sagas'

import maintenanceCreate from './redux/create/reducers'
import maintenanceCreateSaga from './redux/create/sagas'

import maintenanceUpdate from './redux/update/reducers'
import maintenanceUpdateSaga from './redux/update/sagas'

import maintenanceDone from './redux/done/reducers'
import maintenanceDoneSaga from './redux/done/sagas'

import maintenanceDestroy from './redux/destroy/reducers'
import maintenanceDestroySaga from './redux/destroy/sagas'

import maintenanceMap from './redux/map/reducers'
import maintenanceMapSaga from './redux/map/sagas'

import maintenanceBulkCreate from './redux/bulkCreate/reducers'
import maintenanceBulkCreateSaga from './redux/bulkCreate/sagas'

import maintenanceBulkDelete from './redux/bulkDelete/reducers'
import maintenanceBulkDeleteSaga from './redux/bulkDelete/sagas'

export { default as Form } from './pages/details/Form'
export { create } from './redux/create/actions'
export { default as doneActions, done } from './redux/done/actions'
export { default as List } from './components/List'
export { default as BaseMap } from './components/BaseMap'
export { default as VehiclesLayer } from './components/VehiclesLayer'
export { default as VehicleScore, VehicleScoreOverall } from './components/VehicleScore'

export default {
  name: 'maintenance',
  menu: [
    {
      title: 'Maintenance',
      key: 'maintenance',
      icon: 'icmn icmn-map',
      roles: ['admin', 'fleet-operator', 'bike-operator', 'fleet-owner'],
      children: [
        {
          title: 'Map',
          key: 'maintenance-all-map',
          url: '/maintenance/map/vehicles/all',
        },
        {
          title: 'Cars map',
          key: 'maintenance-cars-map',
          url: '/maintenance/map/vehicles/cars',
          roles: ['admin', 'fleet-operator', 'bike-operator'],
        },
        {
          title: 'Bikes map',
          key: 'maintenance-bikes-map',
          url: '/maintenance/map/vehicles/bikes',
          roles: ['admin', 'fleet-operator', 'bike-operator'],
        },
        {
          title: 'Services map',
          key: 'maintenance-services-map',
          url: '/maintenance/map/services',
          roles: ['admin', 'fleet-operator', 'bike-operator'],
        },
        {
          title: 'To do',
          key: 'maintenance-todo',
          url: '/maintenance/filter/todo',
          roles: ['admin', 'fleet-operator', 'bike-operator'],
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/maintenance/filter/:filter?', '/maintenance'],
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/maintenance/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
    {
      path: '/maintenance/map/vehicles/:filter?',
      component: Loadable(() => import('./pages/map/vehicles')),
      exact: true,
    },
    {
      path: '/maintenance/map/services',
      component: Loadable(() => import('./pages/map/services')),
      exact: true,
    },
    {
      path: '/maintenance/bulk/:action',
      component: Loadable(() => import('./pages/bulk')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    maintenance,
    maintenanceDetails,
    maintenanceCreate,
    maintenanceUpdate,
    maintenanceDone,
    maintenanceDestroy,
    maintenanceMap,
    maintenanceBulkCreate,
    maintenanceBulkDelete,
  },
  sagas: [
    maintenanceSaga(),
    maintenanceDetailsSaga(),
    maintenanceCreateSaga(),
    maintenanceUpdateSaga(),
    maintenanceDoneSaga(),
    maintenanceDestroySaga(),
    maintenanceMapSaga(),
    maintenanceBulkCreateSaga(),
    maintenanceBulkDeleteSaga(),
  ],
}

import React from 'react'
import { Tag } from 'antd'

export default class MaintenanceTypeTag extends React.Component {
  getTag = tag => {
    let color = 'green'

    switch (tag) {
      case 'wheel':
        color = 'gold'
        break
      case 'document':
        color = 'purple'
        break
      case 'service':
        color = 'magenta'
        break
      case 'maintenance':
      default:
        color = 'green'
    }

    return <Tag color={color}>{tag}</Tag>
  }

  render() {
    const { tag } = this.props

    return this.getTag(tag)
  }
}

import { getToken, net } from 'ca-admin-core'
import { API_URL } from 'utils/constants'

const { get, putJson, postJson, destroy } = net

const API_LAYERS = `${API_URL}/layers`

export const layers = params => get(API_LAYERS, params, getToken())
export const layerDetails = id => get(`${API_LAYERS}/${id}`, null, getToken())
export const layerCreate = params => postJson(API_LAYERS, params, getToken())
export const layerUpdate = (id, params) => putJson(`${API_LAYERS}/${id}`, params, getToken())
export const layerDestroy = id => destroy(`${API_LAYERS}/${id}`, null, getToken())

import { getToken, net } from 'ca-admin-core'
import { FLEET_API_URL } from 'utils/constants'

const { get, putJson, postJson, destroy } = net

export const url = `${FLEET_API_URL}/owners`

export const fleets = params => get(url, params, getToken())
export const fleetDetails = id => get(`${url}/${id}`, null, getToken())
export const fleetCreate = params => postJson(url, params, getToken())
export const fleetUpdate = (id, params) => putJson(`${url}/${id}`, params, getToken())
export const fleetDestroy = id => destroy(`${url}/${id}`, null, getToken())

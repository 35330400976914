import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userUpdateLicense } = API

function* UPDATE_LICENSE({ payload: { id, params } }) {
  yield put(request(userUpdateLicense(id, params), actions.UPDATE_LICENSE))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'License updated.',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_LICENSE, UPDATE_LICENSE),
    takeLatest(actions.UPDATE_LICENSE_SUCCESS, SUCCESS),
  ])
}

import plans from './list/reducers'
import plansDetails from './show/reducers'
import plansUpdate from './update/reducers'
import plansCreate from './create/reducers'
import plansDestroy from './destroy/reducers'

export default {
  plans,
  plansDetails,
  plansUpdate,
  plansCreate,
  plansDestroy,
}

import { networkActions, extraNetworkActions } from 'ca-admin-core'

const actions = {
  LOAD: 'canboxDetails/load',
  ...networkActions('canboxDetails'),
  ...extraNetworkActions('UPDATE', 'canboxDetails/update'),
}

export default actions

export function load(id) {
  return {
    type: actions.LOAD,
    payload: {
      id,
    },
  }
}

export function update(id, params) {
  return {
    type: actions.UPDATE,
    payload: {
      id,
      params,
    },
  }
}

export function reset() {
  return {
    type: actions.RESET,
  }
}

import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/users/api'
import actions from './actions'

const { userDestroy } = API

function* DESTROY({ payload: { id } }) {
  yield put(request(userDestroy(id), actions.DESTROY))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'User deleted.',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.DESTROY, DESTROY), takeLatest(actions.DESTROY_SUCCESS, SUCCESS)])
}

import { put, select, all, takeLatest } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import { metadata } from '../../api'

const getUser = state => state.user

function* LOAD() {
  const user = yield select(getUser)

  if (user.authorized) {
    yield put(request(metadata(), 'metadata'))
  }
}

export default function* rootSaga() {
  yield all([LOAD(), takeLatest('auth/success', LOAD)])
}

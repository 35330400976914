import { Loadable } from 'ca-admin-core'

import canboxes from './redux/list/reducers'
import canboxesSaga from './redux/list/sagas'

import canboxDetails from './redux/details/reducers'
import canboxDetailsSaga from './redux/details/sagas'

export default {
  name: 'canboxes',
  menu: [
    {
      title: 'Canbox',
      key: 'equipment-canbox',
      url: '/equipment/canbox',
    },
  ],
  routes: [
    {
      path: ['/equipment/canbox/filter/:filter?', '/equipment/canbox'],
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/equipment/canbox/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    canboxes,
    canboxDetails,
  },
  sagas: [canboxesSaga(), canboxDetailsSaga()],
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('CLEAR_ALERTS', 'vehicleDetails/clearAlerts'),
}

export default actions

export function clearAlerts(uuid) {
  return {
    type: actions.CLEAR_ALERTS,
    payload: {
      uuid,
    },
  }
}

import { getToken, net } from 'ca-admin-core'
import { API_URL, FLEET_API_URL } from 'utils/constants'

const { get } = net

const API_STATS = `${API_URL}/stats`
const API_ISOCHRONES = `${FLEET_API_URL}/vehicles/vehiclesIsochrones`

export const income = params => get(`${API_STATS}/incomeusage`, params, getToken())
export const incomeExport = params => get(`${API_STATS}/incomeusage/export`, params, getToken())
export const topup = params => get(`${API_STATS}/topupreport`, params, getToken())
export const topupExport = params => get(`${API_STATS}/topupreport/export`, params, getToken())
export const city = params => get(`${API_STATS}/cityreport`, params, getToken())
export const cityExport = params => get(`${API_STATS}/cityreport/export`, params, getToken())
export const unused = params => get(`${API_STATS}/unusedvehicles`, params, getToken())
export const used = params => get(`${API_STATS}/usedvehicles`, params, getToken())
export const usedExport = params => get(`${API_STATS}/usedvehicles/export`, params, getToken())
export const routes = params => get(`${API_STATS}/routes`, params, getToken())
export const heatmap = params => get(`${API_STATS}/heatmap`, params, getToken())
export const events = params => get(`${API_STATS}/events`, params, getToken())
export const isochrones = params => get(API_ISOCHRONES, params, getToken())

export const partners = params => get(`${API_STATS}/partners`, params, getToken())
export const partnersExport = params => get(`${API_STATS}/partners/export`, params, getToken())

export const events_app_opened = params =>
  get(`${API_STATS}/eventsperhourperweekday`, params, getToken())

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('ASSIGN', 'vehicleDetails/assignCanbox'),
}

export default actions

export function assign(uuid, params) {
  return {
    type: actions.ASSIGN,
    payload: {
      uuid,
      params,
    },
  }
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('RETRY_PAYMENT', 'trip/retryPayment'),
}

export default actions

export function retryPayment(uuid) {
  return {
    type: actions.RETRY_PAYMENT,
    payload: {
      uuid,
    },
  }
}

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('TOPUP_WALLET', 'user/wallet/topup'),
}

export default actions

export function topupWallet(id, params) {
  return {
    type: actions.TOPUP_WALLET,
    payload: {
      id,
      params,
    },
  }
}

import { getToken, net } from 'ca-admin-core'
import { FLEET_API_URL } from 'utils/constants'

const { get, postJson, putJson } = net

const API_CANBOXES = `${FLEET_API_URL}/canbox`

export const canboxes = params => get(API_CANBOXES, params, getToken())
export const createCanbox = params => postJson(API_CANBOXES, params, getToken())
export const canboxDetails = id => get(`${API_CANBOXES}/${id}`, null, getToken())
export const updateCanbox = (id, params) => putJson(`${API_CANBOXES}/${id}`, params, getToken())

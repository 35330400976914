import React from 'react'
import { Tag } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import { DataTable } from 'ca-admin-core'

import { BikeLockedTag, BikeBatteryBadge } from 'modules/vehicles'
import { getItem, listItems } from 'utils/metadata'
import { load } from '../redux/bikes/actions'

@withRouter
@connect(({ alertsBikes, metadata }) => ({ alertsBikes, metadata }))
export default class BikeAlertsList extends React.Component {
  interval = null

  componentDidMount() {
    const { autoreload } = this.props

    if (autoreload) {
      this.enableAutoReload()
    }
  }

  componentWillUnmount() {
    this.disableAutoReload()
  }

  load = () => {
    const { dispatch } = this.props
    dispatch(load())
  }

  enableAutoReload = () => {
    this.interval = setInterval(() => this.load(), 30 * 1000)
  }

  disableAutoReload = () => {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  render() {
    const {
      metadata: { alertTypeBike },
      alertsBikes,
      autoreload,
    } = this.props

    const columns = [
      {
        title: 'Type',
        dataIndex: 'alert_type',
        sorter: false,
        filters: !autoreload && listItems(alertTypeBike),
        render: type => <Tag>{getItem(alertTypeBike, type)}</Tag>,
      },
      {
        title: 'Vehicle',
        dataIndex: 'vehicle',
        sorter: false,
        render: vehicle => (
          <Link
            to={{ pathname: `/vehicles/${vehicle.uuid}`, state: { modal: true } }}
            className="utils__link--underlined"
          >
            {vehicle.identification_number}
          </Link>
        ),
      },
      {
        title: 'Battery',
        dataIndex: 'battery_level',
        sorter: !autoreload,
        render: (_, record) => <BikeBatteryBadge data={record.value} />,
      },
      {
        title: 'Locked',
        sorter: false,
        render: record => <BikeLockedTag status={record.status} data={record.value} />,
      },
      {
        title: 'Date',
        dataIndex: 'generated_at',
        sorter: false,
        render: date =>
          moment
            .utc(date)
            .local()
            .format('YYYY-MM-DD HH:mm'),
      },
    ]

    return <DataTable columns={columns} dataSource={alertsBikes} loadAction={load} />
  }
}

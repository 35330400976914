import { Loadable } from 'ca-admin-core'

import vehicleControls from './redux/vehicleControls/reducers'
import vehicleControlsSaga from './redux/vehicleControls/sagas'

export default {
  name: 'logs',
  menu: [
    {
      title: 'Logs',
      key: 'logs',
      icon: 'icmn icmn-file-text2',
      roles: ['admin'],
      children: [
        {
          title: 'Vehicle controls',
          key: 'logs=vehicle-controls',
          roles: ['admin'],
          url: '/logs/vehicle-controls',
        },
      ],
    },
  ],
  routes: [
    {
      path: '/logs/vehicle-controls',
      component: Loadable(() => import('./pages/vehicleControls')),
      exact: true,
    },
  ],
  reducers: { vehicleControls },
  sagas: [vehicleControlsSaga()],
}

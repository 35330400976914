import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('UPDATE_LICENSE', 'user/license/update'),
}

export default actions

export function userUpdateLicenseAction(id, params) {
  return {
    type: actions.UPDATE_LICENSE,
    payload: {
      id,
      params,
    },
  }
}

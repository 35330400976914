import { all, takeLatest, takeEvery, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import API from 'modules/trips/api'
import actions from './actions'

const { retryPayment } = API

function* RETRY_PAYMENT({ payload: { uuid } }) {
  yield put(request(retryPayment(uuid), actions.RETRY_PAYMENT))
}

function* SUCCESS() {
  const lastCharge = yield select(state => state.tripDetails.trip.lastCharge)

  if (!lastCharge) {
    yield notification.error({
      message: 'Failed!',
      description: 'Payment failed!',
    })
    return
  }

  yield notification.success({
    message: 'Done!',
    description: 'Payment successful!',
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.RETRY_PAYMENT, RETRY_PAYMENT),
    takeEvery(actions.RETRY_PAYMENT_SUCCESS, SUCCESS),
  ])
}

import { getToken, net } from 'ca-admin-core'
import { FLEET_API_URL } from 'utils/constants'

const { get, putJson, postJson, destroy } = net

const API_MAINTENANCE = `${FLEET_API_URL}/maintenance`

export const maintenance = params => get(API_MAINTENANCE, params, getToken())
export const maintenanceDetails = id => get(`${API_MAINTENANCE}/${id}`, null, getToken())
export const maintenanceCreate = params => postJson(API_MAINTENANCE, params, getToken())
export const maintenanceUpdate = (id, params) =>
  putJson(`${API_MAINTENANCE}/${id}`, params, getToken())
export const maintenanceDone = id =>
  putJson(`${API_MAINTENANCE}/${id}/done`, { pending: 0 }, getToken())
export const maintenanceDestroy = id => destroy(`${API_MAINTENANCE}/${id}`, null, getToken())
export const maintenanceBulkCreate = params =>
  postJson(`${API_MAINTENANCE}/massStore`, params, getToken())
export const maintenanceBulkDelete = params =>
  postJson(`${API_MAINTENANCE}/massDelete`, params, getToken())

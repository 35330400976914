import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'

import { fleetUpdate } from '../../api'
import actions from './actions'

function* UPDATE({ payload: { id, params } }) {
  yield put(request(fleetUpdate(id, params), actions.UPDATE))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Data successfully saved.',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPDATE, UPDATE), takeLatest(actions.UPDATE_SUCCESS, SUCCESS)])
}

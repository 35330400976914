import { all, takeLatest, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import { vehicleHistory } from '../../api'
import actions from './actions'

function* LOAD({ payload: { uuid, ...params } }) {
  yield put(request(vehicleHistory(uuid, params), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}

import { all, takeLatest, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'
import { startTrip } from 'modules/trips'
import actions from './actions'

function* ADD_TRIP({ payload: { params } }) {
  yield put(request(startTrip(params), actions.ADD_TRIP))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'User trips successfully updated.',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ADD_TRIP, ADD_TRIP), takeLatest(actions.ADD_TRIP_SUCCESS, SUCCESS)])
}

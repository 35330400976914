import React from 'react'
import { Tag } from 'antd'

export default class LightsTag extends React.Component {
  render() {
    const { data, status } = this.props
    const tags = []

    if (!data || !data.cangoStatus || data.cangoStatus.length === 0) {
      return <Tag>n / a</Tag>
    }

    const {
      cangoStatus: { lights },
    } = data

    Object.entries(lights).forEach(([key, value]) => {
      if (value) {
        // lights on tags
        tags.push(
          <Tag key={key} color={status === 'free' ? 'red' : 'gold'}>
            {key}
          </Tag>,
        )
      }
    })

    if (tags.length === 0) {
      tags.push(<Tag key="lights">off</Tag>)
    }

    return tags
  }
}

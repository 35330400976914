import { Loadable } from 'ca-admin-core'

import promocodes from 'modules/promocodes/redux/list/reducers'
import promocodesSaga from 'modules/promocodes/redux/list/sagas'

import promocodeCreate from 'modules/promocodes/redux/create/reducers'
import promocodeCreateSaga from 'modules/promocodes/redux/create/sagas'

import promocodeDetails from 'modules/promocodes/redux/details/reducers'
import promocodeDetailsSaga from 'modules/promocodes/redux/details/sagas'

import promocodeUpdate from 'modules/promocodes/redux/update/reducers'
import promocodeUpdateSaga from 'modules/promocodes/redux/update/sagas'

import promocodeDestroy from 'modules/promocodes/redux/destroy/reducers'
import promocodeDestroySaga from 'modules/promocodes/redux/destroy/sagas'

export default {
  name: 'promocodes',
  menu: [
    {
      title: 'Promotions',
      key: 'promotions',
      icon: 'icmn icmn-price-tag',
      roles: ['admin'],
      children: [
        {
          title: 'All',
          key: 'promocodes',
          url: '/promocodes',
        },
        {
          title: 'Vouchers',
          key: 'vouchers',
          url: '/promocodes/filter/vouchers',
        },
        {
          title: 'Referrals',
          key: 'referrals',
          url: '/promocodes/filter/referrals',
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/promocodes/filter/:filter?', '/promocodes'],
      component: Loadable(() => import('modules/promocodes/pages')),
      exact: true,
    },
    {
      path: '/promocodes/:id',
      component: Loadable(() => import('modules/promocodes/pages/details')),
      exact: true,
      modal: true,
    },
  ],
  reducers: {
    promocodes,
    promocodeCreate,
    promocodeDetails,
    promocodeUpdate,
    promocodeDestroy,
  },
  sagas: [
    promocodesSaga(),
    promocodeCreateSaga(),
    promocodeDetailsSaga(),
    promocodeUpdateSaga(),
    promocodeDestroySaga(),
  ],
}

import { net, getToken } from 'ca-admin-core'
import CONSTANTS from './constants'

const { API } = CONSTANTS
const { get, postJson, putJson, destroy } = net

const promocodes = params => get(API.PROMOCODES, params, getToken())
const promocodeDetails = id => get(`${API.PROMOCODES}/${id}`, null, getToken())
const promocodeUpdate = (id, params) => putJson(`${API.PROMOCODES}/${id}`, params, getToken())
const promocodeCreate = params => postJson(API.PROMOCODES, params, getToken())
const promocodeDestroy = id => destroy(`${API.PROMOCODES}/${id}`, null, getToken())

export default {
  promocodes,
  promocodeDetails,
  promocodeUpdate,
  promocodeCreate,
  promocodeDestroy,
}

import { Loadable } from 'ca-admin-core'

import alertsVehicles from 'modules/alerts/redux/vehicles/reducers'
import alertsVehiclesSaga from 'modules/alerts/redux/vehicles/sagas'

import alertsBikes from 'modules/alerts/redux/bikes/reducers'
import alertsBikesSaga from 'modules/alerts/redux/bikes/sagas'

import alertsMaintenance from 'modules/alerts/redux/maintenance/reducers'
import alertsMaintenanceSaga from 'modules/alerts/redux/maintenance/sagas'

import alertsPushSaga from './redux/push/sagas'

export { default as BikeAlertsList } from './components/BikeAlertsList'

export default {
  name: 'alerts',
  menu: [
    {
      title: 'Alerts',
      key: 'alerts',
      icon: 'icmn icmn-bell',
      roles: ['admin', 'operator', 'fleet-operator', 'bike-operator'],
      children: [
        {
          title: 'Maintenance',
          key: 'alerts-maintenance',
          url: '/alerts/maintenance',
        },
        {
          title: 'Vehicles',
          key: 'alerts-vehicles',
          url: '/alerts/vehicles',
        },
        {
          title: 'Bikes',
          key: 'alerts-bikes',
          url: '/alerts/bikes',
        },
      ],
    },
  ],
  routes: [
    {
      path: ['/alerts/maintenance'],
      component: Loadable(() => import('modules/alerts/pages/maintenance')),
      exact: true,
    },
    {
      path: ['/alerts/vehicles'],
      component: Loadable(() => import('modules/alerts/pages/vehicles')),
      exact: true,
    },
    {
      path: ['/alerts/bikes'],
      component: Loadable(() => import('modules/alerts/pages/bikes')),
      exact: true,
    },
  ],
  reducers: {
    alertsVehicles,
    alertsBikes,
    alertsMaintenance,
  },
  sagas: [alertsVehiclesSaga(), alertsBikesSaga(), alertsMaintenanceSaga(), alertsPushSaga()],
}

import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'

import { maintenanceDestroy } from '../../api'
import actions from './actions'

function* DESTROY({ payload: { id } }) {
  yield put(request(maintenanceDestroy(id), actions.DESTROY))
}

function* SUCCESS() {
  yield notification.success({
    message: 'Done!',
    description: 'Maintenance entry deleted.',
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.DESTROY, DESTROY), takeLatest(actions.DESTROY_SUCCESS, SUCCESS)])
}

import actions from './actions'
import doneActions from '../done/actions'

const initialState = {
  loading: false,
  data: null,
  pagination: null,
}

export default function maintenanceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      }
    case actions.ERROR:
      return {
        ...initialState,
      }
    case actions.RESET:
      return {
        ...initialState,
      }
    case doneActions.DONE_SUCCESS:
      // TO DO improve reducer, switch to indexed approach and use selectors
      return {
        ...state,
        data:
          state.data &&
          state.data.map(item => {
            const id = parseInt(action.payload.data, 10)

            if (item.id !== id) {
              return item
            }

            return {
              ...item,
              pending: 0,
            }
          }),
      }
    default:
      return state
  }
}

import { all, takeLatest, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import API from 'modules/promocodes/api'
import actions from './actions'

const { promocodes } = API

function* LOAD({ payload }) {
  yield put(request(promocodes(payload), 'promocodes'))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}

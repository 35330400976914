import React from 'react'
import { Tag } from 'antd'

export default class LockedTag extends React.Component {
  getTag = (locked, status) => {
    let color
    let lockedStatus

    switch (locked) {
      case false:
        lockedStatus = 'unlocked'
        color = status === 'free' ? 'red' : 'green'
        break
      case true:
        lockedStatus = 'locked'
        color = ''
        break
      default:
        lockedStatus = 'n / a'
        color = 'gold'
    }

    return <Tag color={color}>{lockedStatus}</Tag>
  }

  render() {
    const { data, status } = this.props

    if (!data) {
      return <Tag>n / a</Tag>
    }

    const { locked } = data

    return this.getTag(locked, status)
  }
}

import { all, put, takeLatest } from 'redux-saga/effects'
import { request } from 'ca-admin-core'
import { maintenanceDetails } from '../../api'
import actions from './actions'

function* LOAD({ payload: { id } }) {
  yield put(request(maintenanceDetails(id), 'vehicles/maintenanceDetails'))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}

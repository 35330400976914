import actions from './actions'

const initialState = {
  loading: false,
}

export default function maintenanceDestroy(state = initialState, action) {
  switch (action.type) {
    case actions.DESTROY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.DESTROY_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actions.DESTROY_ERROR:
      return {
        ...initialState,
      }
    case actions.DESTROY_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

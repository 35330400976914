import { all, takeLatest, select } from 'redux-saga/effects'
import { subscribe, unsubscribe } from 'services/Pusher'

const getUser = state => state.user

function* SUBSCRIBE() {
  const user = yield select(getUser)

  if (user.authorized) {
    yield subscribe()
  }
}

function* UNSUBSCRIBE() {
  yield unsubscribe()
}

export default function* rootSaga() {
  yield all([
    SUBSCRIBE(),
    takeLatest('auth/success', SUBSCRIBE),
    takeLatest('user/LOGOUT', UNSUBSCRIBE),
  ])
}

import pricings from './list/sagas'
import pricingDetails from './show/sagas'
import pricingUpdate from './update/sagas'
import pricingCreate from './create/sagas'

export default [
  pricings(),
  pricingDetails(),
  pricingUpdate(),
  pricingCreate(),
]

import { extraNetworkActions } from 'ca-admin-core'

const actions = {
  ...extraNetworkActions('UPDATE_ROLES', 'user/roles'),
}

export default actions

export function userUpdateRoles(id, params) {
  return {
    type: actions.UPDATE_ROLES,
    payload: {
      id,
      params,
    },
  }
}
